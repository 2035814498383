import { configureStore } from '@reduxjs/toolkit';
import metierReducer from './features/metierSlice';
import articleReducer from './features/articleSlice';

const store = configureStore({
  reducer: {
    metier: metierReducer,
    article: articleReducer,
  },
});

export default store;
