import AtIcon from "../../icons/AtIcon";
import "./style.scss"

const ContactCard = ({contact})=> {
    if (!!!contact) return null;
    return (
        <div className="contact-card">
            <ul>
                <li className="contact-card-titre">{contact.cct_titre}</li>
                <li>{contact.cct_description}</li>
                <li><AtIcon /> {contact.cct_mail}</li>
            </ul>
        </div>
    );

}
export default ContactCard;
