import React, { useState } from 'react';
import useAuth from '../../../../hooks/useAuth';
import './style.scss';
import logo from './Logo_AV_v2.png';
import Button from './../../button/Button';
import SearchInput from '../../input/SearchInput';
import { useLocation, useNavigate } from 'react-router-dom';
import { REDIRECT_LOGIN } from '../../../../config/constants';
import { counterMagasinConnexion } from '../../../../services/apis';

const SelectMagasinList = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { user, changeMagasin, onLogout } = useAuth();
  const [selectedMag, setSelectedMag] = useState(null);
  const [filteredMags, setFilteredMags] = useState(null); // Liste des produits filtrés

  const [filter, setFilter] = useState('');

  /** HandleChange filter */
  const handleChangeFilter = (event) => {
    const value = event.target.value;
    setFilter(value);
  };

  React.useEffect(() => setFilteredMags(user.magasins), [user]);

  React.useEffect(() => {
    try {
      let filterProductTemp = user.magasins;
      if (user.magasins.length && filter) {
        filterProductTemp = filterProductTemp.filter((magasin) => {
          // console.log(magasin.mag_nom.toLowerCase());
          return (
            magasin.mag_code
              .toString()
              .toLowerCase()
              .startsWith(filter) ||
            magasin.mag_nom
              .toLowerCase()
              .startsWith(filter.toLowerCase()) ||
            magasin.mag_cp.toLowerCase().startsWith(filter) ||
            magasin.mag_ville.toLowerCase().startsWith(filter)
          );
        });
      }
      setFilteredMags(filterProductTemp);
    } catch (err) {
      console.log(err);
    }
  }, [filter, user.magasins]);

  const handleSelectMagasin = (magasin) => setSelectedMag(magasin);

  const handleValidateSelectMagasin = () => {
    const redirect = location.state?.from?.pathname || REDIRECT_LOGIN;

    changeMagasin(selectedMag);
    // Augmente l'indicateur du nombre de connexion
    counterMagasinConnexion(selectedMag.mag_code);
    navigate(redirect);
  };

  return (
    <div className="select-magasin-list-wrapper">
      <div className="select-magasin-input">
        <SearchInput
          placeholder="Rechercher ..."
          value={filter}
          onChange={(event) => handleChangeFilter(event)}
        />
      </div>

      <div className="select-magasin-list">
        <div className="select-magasin-list__head">
          <h4 className="title">Magasins</h4>
        </div>
        <div className="select-magasin-list__list">
          {!!filteredMags &&
            filteredMags.map((magasin) => (
              <div
                className={`select-mag-card ${
                  selectedMag === magasin ? 'selected' : ''
                }`}
                onClick={() => handleSelectMagasin(magasin)}
                key={`select_mag_card_${magasin.mag_code}`}
              >
                {/* <img className="mag-logo" src={logo} alt="mag-logo" /> */}
                <div className="mag-info">
                  <span className="title">
                    {magasin.mag_nom} - {magasin.mag_ville}
                  </span>
                  <span className="sub-title">
                    Identifiant : {magasin.user_referent.username}
                  </span>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="select-magasin-list__action">
        <Button
          libelle="Retour"
          color="color-secondary"
          onClick={() => onLogout()}
        />

        <Button
          // loading={loading}
          libelle="Suivant"
          color="color-primary"
          disabled={!!!selectedMag}
          onClick={() => handleValidateSelectMagasin()}
        />
      </div>
    </div>
  );
};

export default SelectMagasinList;
