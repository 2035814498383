import React from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
import option1 from './option1.jpg';
import option2 from './option2.jpg';
import option3 from './option3.jpg';
import option4 from './option4.jpg';
import option5 from './option5.jpg';
import option6 from './option6.jpg';

const CLselectionOption = () => {
  return (
    <div className="cl-select-opt">
      <div className="cl-select-opt__content">
        <div className="cl-select-opt__option-list">
          <Link to="/cl/option1" className="cl-option">
            <div className="cl-option__img">
              <img src={option1} alt="option1" />
            </div>
            <div className="cl-option__title">
              ma distribution spécifique
            </div>
          </Link>

          <Link to="/cl/option2" className="cl-option">
            <div className="cl-option__img">
              <img src={option2} alt="option2" />
            </div>
            <div className="cl-option__title">
              mon dépliant spécifique
            </div>
          </Link>
          <Link to="/cl/option3" className="cl-option">
            <div className="cl-option__img">
              <img src={option3} alt="option3" />
            </div>
            <div className="cl-option__title">
              mon sms avec location de base de données
            </div>
          </Link>
        </div>
        <h3 className="cl-select-opt__title">
          Pour les adhérents au programme de fidélité
        </h3>
        <div className="cl-select-opt__option-list">
          <Link to="/cl/option4" className="cl-option">
            <div className="cl-option__img">
              <img src={option4} alt="option4" />
            </div>
            <div className="cl-option__title">
              mon mailing courrier fidélité spécifique
            </div>
          </Link>
          <Link to="/cl/option56/0" className="cl-option">
            <div className="cl-option__img">
              <img src={option5} alt="option5" />
            </div>
            <div className="cl-option__title">
              mon sms fidélité spécifique
            </div>
          </Link>
          <Link to="/cl/option56/1" className="cl-option">
            <div className="cl-option__img">
              <img src={option6} alt="option6" />
            </div>
            <div className="cl-option__title">
              mon e-mailing fidélité spécifique
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CLselectionOption;
