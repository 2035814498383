import useAuth from '../../../../../hooks/useAuth';
import Input from '../../../input/Input';
import React from 'react';
import './style.scss';

const PRFprofilForm = () => {
  const { magasin } = useAuth();

  return (
    <div className="profil-form">
      <h3 className="title">Informations magasin</h3>

      <div className="form-info">
        <Input
          type="text"
          name="mag_nom"
          label="Nom du magasin"
          value={magasin.mag_nom}
          readOnly
          disabled
        />
        <Input
          type="text"
          name="mag_ville"
          label="VILLE"
          value={magasin.mag_ville}
          readOnly
          disabled
        />
        <Input
          type="text"
          name="mag_ville"
          label="Mail Direction"
          value={magasin.mag_maildir}
          readOnly
          disabled
        />
        <Input
          type="text"
          name="RR"
          label="Votre contact Inédis"
          value={magasin.responsable_reg[0].rr_mail}
          readOnly
          disabled
        />
        <span>
          Pour toutes demandes de modification, passez par votre
          contact Inédis <b>{magasin.responsable_reg[0].rr_nom}</b>
        </span>
      </div>
    </div>
  );
};
export default PRFprofilForm;
