import React, { useRef } from 'react';
import VanillaTilt from 'vanilla-tilt';
import { Link } from 'react-router-dom';
import './style.scss';
const tiltOptions = {
  max: 15,
  scale: 1.01,
  glare: true, // if it should have a "glare" effect
  'max-glare': 0.35, // the maximum "glare" opacity (1 = 100%, 0.5 = 50%)
};
const FPColorCardLink = ({
  to,
  className,
  children,
  externalLink,
}) => {
  const tiltRef = useRef(null);

  React.useEffect(() => {
    VanillaTilt.init(tiltRef.current, tiltOptions);
  }, []);
  return (
    <div ref={tiltRef} className={`ae-option ${className}`}>
      {externalLink ? (
        <a href={to} className="ae-option-link">
          <span className="ae-option-text">{children}</span>
        </a>
      ) : (
        <Link to={to} className="ae-option-link">
          <span className="ae-option-text">{children}</span>
        </Link>
      )}
    </div>
  );
};
export default FPColorCardLink;
