import { Tooltip } from '@mui/material';
import DispoIcon from '../../../icons/DispoIcon';
import NewIcon from '../../../icons/NewIcon';

export const columns = [
  {
    Header: 'Fournisseur',
    accessor: 'contrat.con_nom',
    className: 'normal-table-center',
    Cell: ({ value }) => {
      if (value && value.length > 20) {
        return value.substring(0, 17) + '...';
      } else {
        return value;
      }
    },
  },
  {
    Header: 'Gencod',
    accessor: 'art_gencod',
    filter: 'includes',
    className: 'normal-table-center',
  },
  {
    Header: 'Désignation - Marque',
    accessor: 'art_des',
    filter: 'includes',
    Cell: ({ row }) => {
      const { art_des, art_marque, art_nouveaute } = row.original;
      const showNewIcon = art_nouveaute === -1;
      const showMarque = art_marque.length > 2;

      return (
        <span>
          {showNewIcon && <NewIcon />}
          {art_des}
          {showMarque && ` - ${art_marque}`}
        </span>
      );
    },
  },
  {
    Header: 'Référence',
    accessor: 'art_reference',
    filter: 'includes',
    className: 'normal-table-center',
  },
  {
    Header: 'PA HT Net',
    accessor: 'art_net',
    className: 'normal-table-numeric',
    Cell: ({ value }) =>
      value
        ? value.toLocaleString('fr-FR', {
            style: 'currency',
            currency: 'EUR',
          })
        : '',
  },
  {
    Header: 'Prix à venir',
    accessor: 'att_net',
    className: 'normal-table-numeric',
    Cell: ({ row }) => {
      const attArtNet = row.values.att_net;
      const attDateDeb = row.values.att_datedeb;

      if (attArtNet) {
        return (
          <div className="tooltip">
            <span>
              {attArtNet.toLocaleString('fr-FR', {
                style: 'currency',
                currency: 'EUR',
              })}
            </span>
            <span className="tooltip-text">
              Tarif à venir le {attDateDeb}
            </span>
          </div>
        );
      }

      return '';
    },
  },
  {
    Header: '% EVO',

    className: 'normal-table-numeric',
    Cell: ({ row }) => {
      const artNet = row.values.art_net;
      const attArtNet = row.values.att_net;
      if (attArtNet) {
        const evolution = ((attArtNet - artNet) / artNet) * 100;
        return `${evolution.toFixed(2)}%`;
      }
      return '';
    },
  },
  {
    Header: 'TVA',
    accessor: 'tva_taux',
    className: 'normal-table-center',
  },
  {
    Header: 'Ecotaxe HT',
    accessor: 'art_eco_ht',
    className: 'normal-table-numeric',
    Cell: ({ value }) =>
      value
        ? value.toLocaleString('fr-FR', {
            style: 'currency',
            currency: 'EUR',
          })
        : '',
  },
  {
    Header: 'PCB',
    accessor: 'art_pcb',
    className: 'normal-table-center',
  },
  {
    Header: 'Min Cmd',
    accessor: 'art_mincmd',
    className: 'normal-table-center',
  },
  {
    Header: 'PV Sug',
    accessor: 'art_pvs',
    className: 'normal-table-numeric',
    Cell: ({ value }) =>
      value
        ? value.toLocaleString('fr-FR', {
            style: 'currency',
            currency: 'EUR',
          })
        : '',
  },
  {
    Header: 'Marge',
    accessor: 'art_marge',
    className: 'normal-table-numeric',
    Cell: ({ value }) => {
      const formattedValue = value ? `${value.toFixed(2)}%` : '';
      return <span>{formattedValue}</span>;
    },
  },
  {
    Header: 'Date fin',
    accessor: 'art_valid_fin',
    Cell: ({ value }) => {
      const date = new Date(value);
      return date.toLocaleDateString('fr-FR');
    },
    className: 'normal-table-center',
  },

  {
    Header: 'Dispo',
    accessor: 'st_code',
    filter: 'includes',
    className: 'normal-table-center',
    Cell: ({ row }) => {
      const { dispo, dp_date_maj } = row.original;
      if (dispo) {
        const { st_color, dp_reappro_value } = dispo;
        const formattedDate = new Date(
          dp_date_maj
        ).toLocaleDateString('fr-FR');
        let tooltipText = 'Date de mise à jour ' + formattedDate;

        if (row.original.st_code === 3 && dp_reappro_value) {
          tooltipText +=
            ' - Réapprovisionnement : ' + dp_reappro_value;
        }
        return (
          <Tooltip title={tooltipText}>
            <div>
              <DispoIcon color={st_color} height={18} width={18} />
            </div>
          </Tooltip>
        );
      } else {
        return null;
      }
    },
  },
];
