import React, { useState } from 'react';
import './style.scss';
import { DOMAIN } from '../../../../../config/env';
import { RESSOURCE } from '../../../../../config/constants';

const UPbookCard = ({ book }) => {
  const [hoverBook, setHoverBook] = useState(false);

  if (!book) return null;
  return (
    <div
      className="book-card"
      onMouseEnter={() => setHoverBook((state) => !state)}
      onMouseLeave={() => setHoverBook((state) => !state)}
    >
      <img
        className="book-card__img"
        src={DOMAIN.URL + '/' + RESSOURCE.BOOK + book.book_file}
        alt=""
      />
      {hoverBook && (
        <>
          <div className="book-links">
            <span className="title"> Téléchargement(s)</span>

            {(() => {
              const bookLinks = [];
              for (let j = 1; j <= 3; j++) {
                const attributeName = `book_c${j}`;
                if (
                  book.hasOwnProperty(attributeName) &&
                  book[attributeName]
                ) {
                  bookLinks.push(
                    <a
                      href={
                        DOMAIN.URL +
                        '/' +
                        RESSOURCE.BOOK_CATALOGUE +
                        book[attributeName]
                      }
                      className="book-link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Partie&nbsp;{j}
                    </a>
                  );
                }
              }
              return bookLinks;
            })()}
          </div>
        </>
      )}
    </div>
  );
};

export default UPbookCard;
