import React from 'react';
import CLdevisLayout from '../../../../components/layout/CLdevisLayout';
import CLdocSpeForm from '../../../../components/ui/forms/communication/CldocSpeForm';

const CLdocSpe = () => {
  return (
    <CLdevisLayout
      title="Document spécifique"
      alert="pour les documents avec distribution la demande doit être effectuée au minimum 7 semaines avant le début de l'opération"
    >
      <CLdocSpeForm />
    </CLdevisLayout>
  );
};

export default CLdocSpe;
