import React from 'react';
import './style.scss';

const PageBannerLayout = ({ children, imageHeader }) => {
  return (
    <div className="page-banner-layout">
      <header className="page-banner-layout__banner">
        <img
          className="page-banner"
          src={imageHeader}
          alt="banniere"
        />
      </header>
      <div className="page-banner-layout__bg"></div>
      <div className="page-banner-layout__outlet">{children}</div>
    </div>
  );
};

export default PageBannerLayout;
