import React from 'react';
import logo from './Logo_INEDIS_b_v2.png';
import './style.scss';
import SelectMagasinList from '../../../components/ui/lists/SelectMagasinList';

const SelectMagasin = () => {
  return (
    <div className="select-magasin-wrapper">
      <header className="select-magasin-head">
        <img className="logo" src={logo} alt="logo" />
      </header>
      <section className="select-magasin">
        <h3 className="select-magasin-title">
          Selectionner un magasin
        </h3>
        <SelectMagasinList />
      </section>
    </div>
  );
};

export default SelectMagasin;
