import React from 'react';
import { useTable, useGlobalFilter } from 'react-table';
import './style.scss';
import SearchInput from '../../input/SearchInput';

const NormalTable = ({
  columns,
  data,
  actions,
  hideGlobalFilter = false,
}) => {
  const tableInstance = useTable({ columns, data }, useGlobalFilter);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter: setTableGlobalFilter,
  } = tableInstance;

  const { globalFilter: tableGlobalFilter } = state; // Récupérer la valeur du filtre global du state
  if (!data.length) return <> Pas de données</>;
  return (
    <div className="normal-table-component">
      {/* Utiliser la valeur du filtre global du state */}
      <div className="normal-table-head">
        {!hideGlobalFilter && (
          <SearchInput
            placeholder="Rechercher ..."
            value={tableGlobalFilter || ''}
            onChange={(e) => setTableGlobalFilter(e.target.value)}
          />
        )}

        {actions}
      </div>

      <div className="normal-table-overlay">
        <div className="normal-table-wrapper">
          <table {...getTableProps()} className="normal-table-table">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className="normal-table-header"
                >
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, index) => {
                prepareRow(row);
                const rowClassName =
                  index % 2 === 0
                    ? 'normal-table-td1'
                    : 'normal-table-td2';
                return (
                  <tr {...row.getRowProps()} className={rowClassName}>
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        className={cell.column.className}
                      >
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default NormalTable;
