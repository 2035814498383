import React from 'react';

const Path = (props) => (
  <path
    fill="transparent"
    strokeWidth="2.5"
    stroke="currentColor"
    strokeLinecap="round"
    {...props}
  />
);

const ArrowRightIcon = ({ width = 20, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24">
      <Path d="M 15 5.5 L 22 12" />
      <Path d="M 6.5 12 L 19 12" />
      <Path d="M 15 18.5 L 22 12" />
      <Path d="M 2 18.5 L 2 5.5" />
    </svg>
  );
};

export default ArrowRightIcon;
