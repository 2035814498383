import React from 'react';
import banniere from './banniere-bdc.png';
import './style.scss';
import PageBannerLayout from '../PageBannerLayout';
import Drawer from '../../ui/drawers/Drawer';
import { PATH } from '../../../config/constants';
import { useLocation } from 'react-router-dom';

const BDCLayout = ({ children }) => {
  const location = useLocation();

  const links = [
    {
      to: PATH.BDC_PROSPECTUS_PAGE,
      libelle: 'Prospectus',
      type: 'outlined',
      class: location.pathname.startsWith(PATH.BDC_PROSPECTUS_PAGE)
        ? 'selected-link'
        : '',
    },
    {
      to: PATH.BDC_COMMANDES_ALL_PAGE,
      libelle: 'Commandes',
      type: 'outlined',
      class: !location.pathname.startsWith(PATH.BDC_PROSPECTUS_PAGE)
        ? 'selected-link'
        : '',
    },
  ];
  return (
    <PageBannerLayout imageHeader={banniere}>
      <div className="bdc-layout">
        <div className="bdc-layout__outlet">{children}</div>
        <div className="bdc-layout__drawer">
          <Drawer links={links} />
        </div>
      </div>
    </PageBannerLayout>
  );
};

export default BDCLayout;
