import React from 'react';
import './style.scss';

const SpinLoader = () => {
  return (
    <>
      <div className="spin-loader-wrapper">
        <div
          data-uia="loading-indicator"
          className="basic-spinner basic-spinner-light"
        ></div>
      </div>
    </>
  );
};

export default SpinLoader;
