import {DOMAIN} from "../config/env";
import api from "./apiClient";

export async function getOpeAccueil(magasin) {
    let operations={};
    await api
        .get(
            DOMAIN.URL_API + 'comAccueil/' + magasin,
        )
        .then((res) => {
            operations = res.data;
        });
    return operations;
}