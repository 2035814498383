import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';

const NavLink = ({
  link,
  selected,
  onClick,
  action = () => {},
  openDropdown,
  visible = true,
}) => {
  const [open, setOpen] = useState(openDropdown);

  React.useEffect(() => setOpen(openDropdown), [openDropdown]);

  return (
    visible && (
      <li
        className={`nav-link`}
        onClick={() => {
          onClick();
        }}
      >
        {link.items ? (
          <button
            className={['title', selected && 'selected'].join(' ')}
            onClick={() => setOpen(true)}
          >
            {link.label}
          </button>
        ) : (
          <Link to={link.to} className="title">
            {link.label}
          </Link>
        )}

        {link.items && (
          <ul className={`sub-dropdown ${open ? 'show' : ''}`}>
            {link.items.map((subLink) => (
              <li key={subLink.label}>
                {subLink.external ? (
                  <a
                    href={subLink.external}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => setOpen(false)}
                  >
                    {subLink.label}
                  </a>
                ) : (
                  <Link
                    to={subLink.to}
                    onClick={() => {
                      setOpen(false);
                      if (subLink?.onClick) subLink.onClick();
                    }}
                  >
                    {subLink.label}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        )}
      </li>
    )
  );
};

export default NavLink;
