import React from 'react';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../../../utils/dates';
import { compose } from '../../../../../utils/hoc';
import BDCdeleteCommandeButton from '../../../button/BDCdeleteCommandeButton';
import BDCExcelCommandButton from '../../../button/BDCExcelCommandButton';
import BDCprintCommandButton from '../../../button/BDCprintCommandButton';
import './style.scss';

const withNoDataFeedback = (feedback) => (Component) => (props) => {
  if (!props.fournisseurs) return <div>{feedback}</div>;
  return <Component {...props} />;
};

const withDataEmptyFeedback =
  (feedback) => (Component) => (props) => {
    if (!props.fournisseurs.length) return <div>{feedback}</div>;
    return <Component {...props} />;
  };

const BaseFournisseurTable = ({ prospectus, fournisseurs }) => {
  return (
    <table className="bdc-cft-table">
      <thead>
        <tr className="bdc-cft-table__row">
          <th>Fournisseur</th>
          <th>Date limite</th>
          <th>Franco</th>
          {/* <th>Total</th> */}
          <th className="action-head">Action</th>
        </tr>
      </thead>
      <tbody>
        {fournisseurs.map((fournisseur) => {
          // Get total command price
          return (
            <tr className="bdc-cft-table__row">
              <td className="string">
                {/* <li className="list-fournisseur-prospectus__btn-list"> */}
                {`${fournisseur.con_nom}`}

                {/* </li> */}
              </td>
              <td className="date">
                {fournisseur.psp_limitecode === 1 ? (
                  <>{formatDate(fournisseur.psp_limitecmd1)}</>
                ) : (
                  <>
                    {formatDate(fournisseur.psp_limitecmd0)} au{' '}
                    {formatDate(fournisseur.psp_limitecmd1)}
                  </>
                )}
              </td>
              <td className="franco">{fournisseur.psp_franco}</td>
              {/* <td className="number">100,000&nbsp;€</td> */}
              <td>
                <div className="action">
                  <Link
                    to={`/bdc/commanderecapfournisseur/${prospectus.psp_cprospectus}/${fournisseur.con_code}`}
                  >
                    <button className="btn-primary"> voir</button>
                  </Link>
                  <BDCprintCommandButton fournisseur={fournisseur} />
                  <BDCExcelCommandButton fournisseur={fournisseur} />
                  <BDCdeleteCommandeButton
                    codeCommand={fournisseur.bi_code}
                    path={''}
                  />
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const FournisseurTable = compose(
  withDataEmptyFeedback('Aucuns produits commandés'),
  withNoDataFeedback('Récupération des commandes...')
)(BaseFournisseurTable);

const BDCcommandeFournisseurTable = ({
  prospectus,
  fournisseurs,
}) => {
  return (
    <div className="bdc-cft">
      <h3>{prospectus.psp_libelle}</h3>
      <div className="bdc-cft__main">
        <FournisseurTable
          fournisseurs={fournisseurs}
          prospectus={prospectus}
        />
      </div>
    </div>
  );
};

export default BDCcommandeFournisseurTable;
