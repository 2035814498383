import React, { useState } from 'react';
import FormControlOverlay from '../FormControlOverlay';
import EyeSlashIcon from '../../icons/EyeSlashIcon';
import EyeIcon from '../../icons/EyeIcon';

const Input = ({
  label,
  type = 'text',
  onChange,
  error = '',
  ...rest
}) => {
  const [seePassword, setSeePassword] = useState(type !== 'password');
  const togglePassword = () => {
    setSeePassword(!seePassword);
  };
  return (
    <FormControlOverlay label={label} error={error}>
      <input
        {...rest}
        onChange={onChange}
        type={seePassword ? 'text' : 'password'}
      />
      {type === 'password' && (
        <button
          type="button"
          className="icon"
          onClick={togglePassword}
          tabIndex="-1"
        >
          {seePassword ? (
            <EyeSlashIcon width="15" height="15" />
          ) : (
            <EyeIcon width="15" height="15" />
          )}
        </button>
      )}
    </FormControlOverlay>
  );
};

export default Input;
