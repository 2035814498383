import React from 'react';
import banniere from './banniere.png';
import PageBannerLayout from '../../PageBannerLayout';

const FournisseursProduitsLayout = ({ children }) => {
  return (
    <PageBannerLayout imageHeader={banniere}>
      {children}
    </PageBannerLayout>
  );
};

export default FournisseursProduitsLayout;
