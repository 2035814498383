import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAuth from '../../../../../hooks/useAuth';
import {getArticlesSupprimes} from '../../../../../services/fournisseurs_produits_apis';
import NormalTable from '../../../../../components/ui/tables/NormalTable';

import Loader from '../../../../../components/ui/loader/Loader';
import ReturnBtn from '../../../../../components/ui/button/ReturnBtn';
import './style.scss';
import moment from "moment";


const ArticlesSup = () => {
    const { magasin } = useAuth();
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const { con_code } = useParams();
    const fetchArticles = useCallback(async () => {
        try {
            const data = await getArticlesSupprimes(magasin.mag_code, con_code);
            setArticles(data);
            setLoading(false);
        } catch (err) {
            console.log(err + 'con_code:' + con_code);
        }
    }, [magasin.mag_code, con_code]);
    const columns = useMemo(
        () => [
            {
                Header: 'Gencod',
                accessor: 'artsup_gencode',
                filter: 'includes',
            },
            {
                Header: 'Designation',
                accessor: 'artsup_des',
                filter: 'includes',
            },
            {
                Header: 'Référence',
                accessor: 'artsup_reference',
                filter: 'includes',
            },
            {
                Header: 'Fournisseur',
                accessor: 'contrat.con_nom',
                filter: 'includes',
            },
            {
                Header: 'Date de suppression',
                accessor: 'artsup_datesup',
                Cell: ({ value }) => moment(value).format('DD/MM/YYYY'),
            },


        ],
        [] // Assurez-vous de passer un tableau vide ici pour les dépendances
    );
    React.useEffect(() => {
        fetchArticles();
    }, [fetchArticles]);
    if (loading) return <Loader />;
    return (
        <div className="articles-sup">
            <div className="articles-sup-title"><h3>Liste des articles récement supprimés</h3></div>

            <ReturnBtn />
           <div className="article-sup-table">
                <NormalTable
                    columns={columns}
                    data={articles}
                />
            </div>
        </div>
    );
};
export default ArticlesSup;
