import React from 'react';
import PageBannerLayout from '../PageBannerLayout';
import banniere from './banniere.png';
import { PATH } from '../../../config/constants';
import Drawer from '../../ui/drawers/Drawer';
import './style.scss';
import useAuth from '../../../hooks/useAuth';
import { coloredLinks, links } from './links';
import AcceuilBannerCarousel from '../../ui/carousels/AcceuilBannerCarousel';

// eslint-disable-next-line react-hooks/rules-of-hooks
//
const HomeLayout = ({ children }) => {
  const { magasin } = useAuth();
  // Liens pour le drawer

  // Application de la couleur 1/2

  return (
    <div className="page-banner-layout">
      <header className="page-banner-layout__banner">
        <AcceuilBannerCarousel />
      </header>
      <div className="page-banner-layout__bg"></div>
      <div className="page-banner-layout__outlet">
        <div className="home-layout">
          <div className="home-layout__content">{children}</div>
          <Drawer links={coloredLinks(magasin)} />
        </div>
      </div>
    </div>
  );
};

export default HomeLayout;
