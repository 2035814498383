import PageBannerLayout from '../PageBannerLayout';
import banniere from './salon_banner.png';

const SalonLayout = ({ children }) => {
  return (
    <PageBannerLayout imageHeader={banniere}>
     
      {children}
    </PageBannerLayout>
  );
};
export default SalonLayout;
