import * as Yup from 'yup';

import { TODAY_DATE, toWeek } from '../../../../../utils/dates';
import { ERROR_MESSAGE } from '../../../../../config/constants';

// Validation des données du formulaire
const schema = Yup.object().shape({
  email: Yup.string().required('* Le champs est obligatoire'),
  sem_distri: Yup.number()
    .integer()
    .required(ERROR_MESSAGE.REQUIRED)
    .min(toWeek(TODAY_DATE), ERROR_MESSAGE.MIN_WEEK + toWeek(TODAY_DATE)),
  nom_doc: Yup.string()
    .max(255, ERROR_MESSAGE.OVERTEXT)
    .required(ERROR_MESSAGE.REQUIRED)
    .matches(/^(?!\s+$).*/, ERROR_MESSAGE.REQUIRED),
  cmz_code: Yup.number()
    .required(ERROR_MESSAGE.REQUIRED)
    .min(0, ERROR_MESSAGE.REQUIRED),
  cmdt_code: Yup.number().required(ERROR_MESSAGE.REQUIRED),
});

// Fonction de validation des données du formulaire
const validation = async (data) => {
  const errorBag = {};
  await schema.validate(data, { abortEarly: false }).catch((err) => {
    err?.inner.forEach((res) => {
      errorBag[res.error_message] = res.message;
    });
  });
  return errorBag;
};

export default validation;
