import useAuth from "../../../hooks/useAuth";
import FournisseursProduitsLayout from "../../../components/layout/_fournisseurs_produits/FournisseursProduitsLayout";
import React, {useCallback, useState} from "react";
import {getDownloadsByCategory} from "../../../services/apis";
import {RESSOURCE, SOUS_RUBRIQUE} from "../../../config/constants";
import {DOMAIN} from "../../../config/env";
import PDFIcon from "../../../components/ui/icons/PDFIcon";
import Loader from "../../../components/ui/loader/Loader";
import TitleRowTable from "../../../components/ui/tables/TitleRowTable";
import './style.scss';



const AideAchatVte = () => {
    const {magasin} = useAuth();
    const [categoryWithDownloadList, setCategoryWithDownloadList] =
        useState({});
    const [loading, setLoading] = useState(true); // Indicate the call has not finished. Default to true

    /**
     * Récupère les telecgargments pour le magasin
     */
    const fetchSections = useCallback(async () => {
        try {
            const data = await getDownloadsByCategory(
                magasin.mag_code,
                SOUS_RUBRIQUE.AIDE_ACHAT_VTE
            );
            setCategoryWithDownloadList(data);
        } catch (err) {
            console.log(err);
        }
    }, [magasin.mag_code]);

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                await fetchSections();
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [fetchSections]);

    const titleRow = { accessor: 'dc_libelle' };

    const dataRow = { accessor: 'downloads' };

    const columns = React.useMemo(
        () => [
            {

                accessor: 'dl_libelle',
            },
            {
                Cell: (row) => (
                    <a
                        href={`${DOMAIN.URL}/${RESSOURCE.DOWNLOAD}/${row.dl_fichier}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <PDFIcon width={20} height={20} />
                    </a>
                ),
            },
        ],
        []
    );

    if (loading) return (
        <FournisseursProduitsLayout>
            <Loader />
        </FournisseursProduitsLayout>
    );
    if (!!!categoryWithDownloadList.length)
        return <FournisseursProduitsLayout> </FournisseursProduitsLayout>;
    return (
        <FournisseursProduitsLayout>
            <div className="aide-achat-vte"><h3 className="aide-achat-vte-title">Aide Achat / Vente</h3>
                <section className="aide-achat-vte-table">
                    <TitleRowTable
                        columns={columns}
                        data={categoryWithDownloadList}
                        titleRow={titleRow}
                        dataRow={dataRow}
                        header={false}
                    />
                </section>
            </div>
        </FournisseursProduitsLayout>
    );
}
export default AideAchatVte;
