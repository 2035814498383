import PasswordInput from '../../../input/PasswordInput';
import Input from '../../../input/Input';
import { PATH } from '../../../../../config/constants';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../../../hooks/useAuth';
import React, { useState } from 'react';
import * as Yup from 'yup';
import './style.scss';
import { updatePassword } from '../../../../../services/auth_apis';
import Button from '../../../button/Button';

const PRFupdatePasswordForm = () => {
  // Données initiales du formulaire
  const freshData = {
    current_password: '',
    password: '',
    password_confirmation: '',
  };
  const [data, setData] = useState(freshData); // Données du formulaire
  const [errors, setErrors] = useState({}); // Erreurs pour le formulaire
  const [error, setError] = useState(''); // Erreur de retour de l'api
  const { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  // Validation des données du formulaire
  const validationSchema = Yup.object().shape({
    current_password: Yup.string().required(
      '* Ce champs est obligatoire'
    ),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,16})/,
        "Le format n'est pas respecté."
      )
      .required('* Ce champs est obligatoire'),
    password_confirmation: Yup.string()
      .oneOf(
        [Yup.ref('password'), null],
        'Le mot de passe ne correspond pas'
      )
      .required('* Ce champs est obligatoire'),
  });

  /** Modification des valeurs des champs */
  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  /**
   * Validation du formulaire
   */
  const handleSubmit = () => {
    // Validation des champs de saisie
    const validate = async () => {
      const errorBag = {};
      await validationSchema
        .validate(data, { abortEarly: false })
        .catch((err) => {
          err?.inner.forEach((res) => {
            errorBag[res.path] = res.message;
          });
        });
      setErrors(errorBag);
      if (Object.keys(errorBag).length) {
        throw new Error('Champs invalides');
      }
    };
    try {
      setError('');
      validate().then(() => {
        setLoading(true);
        updatePassword(data)
          .then(() => {
            setLoading(false);
            setData(freshData);
            // Redirection vers la page d'accueil si premiere modification
            if (!!user.default_password) {
              navigate(PATH.HOME_PAGE);
            }
            setHasChanged(true);
          })
          .catch((err) => {
            setError(err.response.data.errors);
          });
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="update-password-form">
        <h3 className="title">Utilisateur</h3>
        <ul className="user">
          <li>
            <Input
              type="text"
              name="username"
              label="Identifiant de connexion"
              value={user.username}
              disabled
            />
          </li>
          <li>
            <Input
              type="text"
              name="email"
              label="Mail de l'utilisateur"
              value={user.email}
              disabled
            />
          </li>
          <li>
            <i>@dresse utilisée pour réinitialiser le mot de passe</i>
          </li>
        </ul>

        <form className="form">
          <div className="form__inputs">
            <Input
              type="password"
              name="current_password"
              label="Ancien mot de passe"
              value={data.current_password}
              onChange={(event) =>
                handleChange(event.target.name, event.target.value)
              }
              error={errors.current_password}
            />
            <Input
              type="password"
              name="password"
              label="Nouveau mot de passe"
              value={data.password}
              onChange={(event) =>
                handleChange(event.target.name, event.target.value)
              }
              error={errors.password}
            />
            <Input
              type="password"
              name="password_confirmation"
              label="Confirmation mot de passe"
              value={data.password_confirmation}
              onChange={(event) =>
                handleChange(event.target.name, event.target.value)
              }
              error={errors.password_confirmation}
            />
            {error && <span className="error-form">{error}</span>}
          </div>

          <div className="form__action">
            {!hasChanged ? (
              <Button
                libelle="Valider"
                loading={loading}
                onClick={() => handleSubmit()}
              />
            ) : (
              <>Votre mot de passe a été modifié</>
            )}
          </div>
        </form>
        <div className="form-info">
          <h5>Le mot de passe doit être composé de&nbsp;:</h5>
          <ul>
            <li>8 à 16 caractères.</li>
            <li>1 majuscule.</li>
            <li>1 chiffre.</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default PRFupdatePasswordForm;
