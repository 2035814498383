import React, { useCallback, useState } from 'react';
import GeneralLayout from '../../../components/layout/GeneralLayout';
import logo from './logo_INEDIS_blanc.png';
import './style.scss';
import useAuth from '../../../hooks/useAuth';
import { getBooks } from '../../../services/fournisseurs_produits_apis';
import Loader from '../../../components/ui/loader/Loader';
import { getNewsletters } from '../../../services/apis';

const Newsletter = () => {
  const { magasin } = useAuth();
  const [loading, setLoading] = useState({});
  const [newsletters, setNewsletters] = useState([]);
  const [selectedNewsletter, setSelectedNewsletter] = useState({});

  const fetchAPI = useCallback(async () => {
    try {
      const data = await getNewsletters(magasin.mag_code);
      setNewsletters(data);
    } catch (err) {
      console.log(err);
    }
  }, [magasin.mag_code]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchAPI();
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [fetchAPI]);

  React.useEffect(
    () => setSelectedNewsletter(newsletters[0]),
    [newsletters]
  );

  if (loading) return <Loader />;
  if (!newsletters.length || !selectedNewsletter) return null;

  return (
    <GeneralLayout>
      <div className="newsletter">
        <header class="newsletter-header">
          <div class="container-title">
            <img class="logo" src={logo} alt="logo-title" />
            <h1 class="title">Les actus d'INEDIS</h1>
          </div>
        </header>
        <div className="newsletter-content">
          <aside className="newsletter-historique">
            <ul>
              {newsletters.map((newsletter) => (
                <li onClick={() => setSelectedNewsletter(newsletter)}>
                  {newsletter.libelle}
                </li>
              ))}
            </ul>
          </aside>
          <section className="newsletter-actus">
            {selectedNewsletter.chronos.map((chrono) => (
              <a
                className="newsletter-actus-card"
                href={`https://extranet.inedis.fr/_fichier/_chrono/${chrono.ch_fichier}`}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={`https://extranet.inedis.fr/_fichier/_chrono_news/${chrono.ch_image}`}
                  alt=""
                />
              </a>
            ))}
          </section>
        </div>
      </div>
    </GeneralLayout>
  );
};

export default Newsletter;
