export const formatPhoneNumber = (
  phoneNumber,
  countryCode = 'FR'
) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(
    /^(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})$/
  );
  if (match) {
    let formattedNumber =
      '(' +
      match[1] +
      ') ' +
      match[2] +
      ' ' +
      match[3] +
      ' ' +
      match[4] +
      ' ' +
      match[5];

    if (countryCode !== 'FR') {
      formattedNumber = `+${countryCode} ${formattedNumber}`;
    } else {
      formattedNumber = `+33 ${formattedNumber}`;
    }
    return formattedNumber;
  }
  return phoneNumber;
};

export function masquerEmail(email) {
  const atIndex = email.indexOf('@'); // index du caractère '@'

  if (atIndex !== -1) {
    const username = email.substring(0, atIndex); // Partie avant '@'
    const domain = email.substring(atIndex); // Partie après '@'

    // Masquer les premiers caractères de l'username sauf les n premiers
    const showCarac = 2; // nombre de caractères a masquer
    const maskedUsername =
      username.substring(0, Math.min(showCarac, username.length)) +
      '*'.repeat(Math.max(username.length - showCarac, 0));

    console.log(maskedUsername);

    const maskedEmail = maskedUsername + domain; // Adresse e-mail masquée
    return maskedEmail;
  } else {
    return email; // Si l'adresse n'a pas de caractère '@', retourner telle quelle
  }
}
