import React from 'react';
import anime from 'animejs';

const Path = React.forwardRef((props, ref) => (
  <path
    ref={ref}
    fill="transparent"
    strokeWidth="2.5"
    stroke="currentColor"
    strokeLinecap="round"
    {...props}
  />
));

const animatePath = (target, d, opacity) => {
  anime({
    targets: target,
    d: [{ value: d }],
    opacity: [{ value: opacity }],
    duration: 500,
    easing: 'easeInOutCirc',
  });
};

const DrawerToggleButton = ({ toggle = () => {}, openDefault }) => {
  const [isOpen, setIsOpen] = React.useState(openDefault);
  const topRef = React.useRef(null);
  const middleRef = React.useRef(null);
  const bottomRef = React.useRef(null);
  const arrowBottomRef = React.useRef(null);

  React.useEffect(() => {
    const topTarget = topRef.current;
    const middleTarget = middleRef.current;
    const bottomTarget = bottomRef.current;
    const arrowBottomTarget = arrowBottomRef.current;

    if (isOpen) {
      animatePath(topTarget, 'M 3 18.5 L 17 5.5', 1);
      animatePath(middleTarget, 'M 2 12 L 20 12', 0);
      animatePath(bottomTarget, 'M 3 5.5 L 17 18.5', 1);
      animatePath(arrowBottomTarget, 'M2 18.5 L2 5.5', 0);
    } else {
      animatePath(topTarget, 'M 9 5.5 L 2 12', 1);
      animatePath(middleTarget, 'M 2 12 L 17.5 12', 1);
      animatePath(bottomTarget, 'M 9 18.5 L 2 12', 1);
      animatePath(arrowBottomTarget, 'M22 18.5 L22 5.5', 1);
    }
  }, [isOpen]);

  return (
    <button
      onClick={() => {
        toggle();
        setIsOpen(!isOpen);
      }}
    >
      <svg width="20" height="20" viewBox="0 0 24 24">
        {/* <rect x="0" y="0" width="24" height="24" /> */}
        <Path d="M 9 5.5 L 2 12" ref={topRef} />
        <Path d="M5 12 L 19 9.423" ref={middleRef} />
        <Path d="M 9 18.5 L 2 12" ref={bottomRef} />
        <Path d="M2 18.5 L2 5.5" ref={arrowBottomRef} />
      </svg>
    </button>
  );
};
export default DrawerToggleButton;
