import * as Yup from 'yup';

import { TODAY_DATE } from '../../../../../utils/dates';
import { ERROR_MESSAGE } from '../../../../../config/constants';

// Validation des données du formulaire
const schema = Yup.object().shape({
  date_valid_deb: Yup.date().required(ERROR_MESSAGE.REQUIRED),
  date_valid_fin: Yup.date()
    .required(ERROR_MESSAGE.REQUIRED)
    .min(TODAY_DATE, ERROR_MESSAGE.MIN_DATE + TODAY_DATE),
  landing: Yup.string().required(ERROR_MESSAGE.REQUIRED),
  ciblage: Yup.string().required(ERROR_MESSAGE.REQUIRED),
  email: Yup.string().required(ERROR_MESSAGE.REQUIRED),
  envoi_sms: Yup.date()
    .required(ERROR_MESSAGE.REQUIRED)
    .min(TODAY_DATE, ERROR_MESSAGE.MIN_DATE + TODAY_DATE),
  heure_sms: Yup.string().required(ERROR_MESSAGE.REQUIRED),
  expediteur: Yup.string().required(ERROR_MESSAGE.REQUIRED),
  coordonnees: Yup.string().required(ERROR_MESSAGE.REQUIRED),
});

// Fonction de validation des données du formulaire
const validation = async (data) => {
  const errorBag = {};
  await schema.validate(data, { abortEarly: false }).catch((err) => {
    err?.inner.forEach((res) => {
      errorBag[res.path] = res.message;
    });
  });
  return errorBag;
};

export default validation;
