import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Modal from '@mui/material/Modal';
import './style.scss';
import { useOutletContext } from 'react-router-dom';
import { formatDate } from '../../../../../utils/dates';

/**
 * Modal that shows for a given prospectus all limite command dates by fournisseurs
 */
const BDClimitDateModal = ({ prospectus }) => {
  const [open, setOpen] = React.useState(false); // Open/close modal
  const { fournisseursList } = useOutletContext(); // All fournisseurs list
  const [fournisseurs, setFournisseurs] = useState(); // fournisseurs of this prospectus

  const handleOpen = () => setOpen(true); // Open modal
  const handleClose = () => setOpen(false); // Close modal

  React.useEffect(() => {
    if (fournisseursList.length >= 1) {
      const fournisseursFiltered = fournisseursList.filter(
        (fournisseur) => {
          return (
            fournisseur.psp_cprospectus === prospectus.psp_cprospectus
          );
        }
      );
      setFournisseurs(fournisseursFiltered);
    }
  }, [fournisseursList, prospectus.psp_cprospectus]);

  return (
    <React.Fragment key={prospectus.psp_cprospectus}>
      <button className="action-button" onClick={handleOpen}>
        Voir les dates limites
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="limit-date-modal">
          <div className="ldm-container">
            <header>
              <h3> {prospectus.psp_libelle} </h3>{' '}
              <span> dates limites de commande</span>
            </header>
            <div className="content">
              {(fournisseurs !== undefined && fournisseurs.length) >=
              1 ? (
                <>
                  <div className="ldm-table">
                    <TableContainer
                      sx={{ width: '90%', maxHeight: 350 }}
                    >
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">
                              Fournisseur
                            </TableCell>
                            <TableCell align="center">Date</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {fournisseurs.map((fournisseur) => {
                            return (
                              <React.Fragment
                                key={fournisseur.con_code}
                              >
                                <TableRow>
                                  <TableCell>
                                    <span
                                      className={`${
                                        new Date(
                                          fournisseur.psp_limitecmd1
                                        ) < new Date()
                                          ? 'old-date'
                                          : ''
                                      }`}
                                    >
                                      {fournisseur.con_nom}
                                    </span>
                                  </TableCell>
                                  <TableCell align="center">
                                    <span
                                      className={`${
                                        new Date(
                                          fournisseur.psp_limitecmd1
                                        ) < new Date()
                                          ? 'old-date'
                                          : ''
                                      }`}
                                    >
                                      {fournisseur.psp_limitecode ===
                                      1 ? (
                                        <>
                                          Commander avant le{' '}
                                          {formatDate(
                                            fournisseur.psp_limitecmd1
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          Commander du{' '}
                                          {formatDate(
                                            fournisseur.psp_limitecmd0
                                          )}{' '}
                                          au{' '}
                                          {formatDate(
                                            fournisseur.psp_limitecmd1
                                          )}
                                        </>
                                      )}
                                    </span>
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </>
              ) : (
                <div className="ldm-error">
                  Aucun fournisseur présent sur ce prospectus{' '}
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default BDClimitDateModal;
