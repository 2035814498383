import React, { useMemo } from 'react';
import MergedRowTable from '../../MergedRowTable';
import StarIcon from '../../../icons/StarIcon';

const ClassementEnum = {
  3: <StarIcon />,
  2: (
    <>
      <StarIcon />
      <StarIcon />
    </>
  ),
  1: (
    <>
      <StarIcon />
      <StarIcon />
      <StarIcon />
    </>
  ),
};

const ClassificationFournisseurTable = ({ classifications }) => {
  // Définition des colonnes de la table en utilisant useMemo pour la performance
  const columns = useMemo(
    () => [
      {
        Header: 'Rayon', // Titre de la colonne
        accessor: 'rayon.ray_lib', // Clé d'accès aux données de cette colonne
        mergeOn: 'ray_code', // Propriété utilisée pour fusionner les cellules de cette colonne
      },
      {
        Header: 'Famille', // Titre de la colonne
        accessor: 'famille.fam_lib', // Clé d'accès aux données de cette colonne
        mergeOn: 'fam_code', // Propriété utilisée pour fusionner les cellules de cette colonne
      },
      {
        Header: 'Sous Famille', // Titre de la colonne
        accessor: 'sous_famille.sfam_lib', // Clé d'accès aux données de cette colonne
        cellProps: { style: { textAlign: 'center' } },
      },
      {
        Header: 'Classement', // Titre de la colonne
        accessor: 'type_libelle.ct_libelle', // Clé d'accès aux données de cette colonne
        mergeOn: 'ct_code', // Propriété utilisée pour fusionner les cellules de cette colonne
        Cell: ({ row }) => ClassementEnum[row.original.ct_code] || '',
        className: 'center-align text-primary',
      },
    ],
    []
  );

  return (
    <MergedRowTable
      data={classifications} // Données à afficher dans la table
      columns={columns} // Colonnes définies précédemment
    />
  );
};

export default ClassificationFournisseurTable;
