import React from 'react';
import useAuth from '../../../hooks/useAuth';
import './style.scss';
import Loader from './../../ui/loader/Loader';
import NavigationBar from './NavigationBar';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';

const AppLayout = () => {
  const { loading } = useAuth();

  if (loading) return <Loader />;
  return (
    <>
      <div className="app-layout">
        <header className="app-layout__nav">
          <NavigationBar />
        </header>
        <main className="app-layout__content">
          <Outlet />
        </main>
        <div className="app-layout__footer">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default AppLayout;
