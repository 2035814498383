import React, { useMemo } from 'react';
import { importImages } from '../../../../utils/image';
import './style.scss';
import useAuth from '../../../../hooks/useAuth';

const AcceuilBannerCarousel = () => {
  const { magasin } = useAuth(); // magasins code

  const images = useMemo(() => {
    let imagesContext;
    if (magasin.interface[0].gr_code < 3) {
      imagesContext = require.context('./images', false);
    } else {
      imagesContext = require.context('./imagesTN', false);
    }
    return importImages(imagesContext);
  }, [magasin.interface]);

  React.useEffect(() => console.log(magasin), [magasin]);

  return (
    <div className="accueil-carousel-wrap">
      <swiper-container
        space-between="15"
        autoplay="true"
        className="swiper-accueil"
        slides-per-view="1"
        speed="500"
      >
        {images.map((image, i) => (
          <swiper-slide
            key={'banner-slide-' + i}
            className="swiper-banner-slide"
          >
            <img className="banner" src={image} alt="" />
          </swiper-slide>
        ))}
      </swiper-container>
    </div>
  );
};

export default AcceuilBannerCarousel;
