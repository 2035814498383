import React, { useEffect, useRef, useState } from 'react';
import useAuth from '../../../../hooks/useAuth';
import { getContratsPhotos } from '../../../../services/apis';
import './style.scss';
import { DOMAIN } from '../../../../config/env';
import { RESSOURCE } from '../../../../config/constants';
import 'swiper/scss';
import 'swiper/scss/autoplay';
import 'swiper/scss/virtual';
import Loader from '../../loader/Loader';

const ContratCarousel = () => {
  const { magasin } = useAuth(); // magasins code
  const [contrats, setContrats] = useState([]);
  const [loading, setLoading] = useState(true);

  React.useEffect(() => {
    const fetchData = async () => {
      const res = await getContratsPhotos(magasin.mag_code);
      console.log(res);
      setContrats(res);
      setLoading(false);
    };
    console.log(contrats);
    fetchData();
  }, [magasin.mag_code]);

  if (loading) return <Loader />;
  return (
    <div className="contrat-carousel-wrap">
      <div class="card">
        <div class="card-content">
          <swiper-container
            space-between="15"
            autoplay="true"
            className="swiper-contrats"
            slides-per-view="3"
            slides-per-group="3"
            speed="500"
          >
            {contrats.map((contrat, i) => (
              <swiper-slide
                key={'contrat-slide-' + i}
                className="swiper-contrat-slide"
              >
                {contrat.con_lien ? (
                  <a
                    href={'https://' + contrat.con_lien}
                    className="image-wrapper"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={`${DOMAIN.URL}/${RESSOURCE.PHOTO_FOURNISSEUR}${contrat.con_image}`}
                      alt=""
                    />
                  </a>
                ) : (
                  <div className="image-wrapper">
                    <img
                      src={`${DOMAIN.URL}/${RESSOURCE.PHOTO_FOURNISSEUR}${contrat.con_image}`}
                      alt=""
                    />
                  </div>
                )}
              </swiper-slide>
            ))}
          </swiper-container>
        </div>
      </div>
    </div>
  );
};

export default ContratCarousel;
