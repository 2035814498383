import React, { useCallback, useState } from 'react';
import './style.scss';
import useAuth from '../../../../../hooks/useAuth';
import { getBooks } from '../../../../../services/fournisseurs_produits_apis';
import { RESSOURCE } from '../../../../../config/constants';
import { DOMAIN } from '../../../../../config/env';
import UPbookCard from '../../../cards/_fournisseurs_produits/UPbookCard';

const UPbookList = () => {
  const { magasin } = useAuth();
  const [loading, setLoading] = useState({});
  const [books, setBooks] = useState([]);

  const fetchAPI = useCallback(async () => {
    try {
      const data = await getBooks(magasin.mag_code);
      setBooks(data);
    } catch (err) {
      console.log(err);
    }
  }, [magasin.mag_code]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchAPI();
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [fetchAPI]);

  if (loading) return <div className="book-list"></div>;
  if (!books.length) return null;
  return (
    <div className="book-list">
      {books.map((book, i) => (
        <UPbookCard book={book} key={'book-' + i} />
      ))}
    </div>
  );
};

export default UPbookList;
