import React from 'react';
import KeyboardReturnSharpIcon from '@mui/icons-material/KeyboardReturnSharp';
import { useNavigate } from 'react-router-dom';
import './style.scss';

const ReturnBtn = ({ link = '' }) => {
  const navigate = useNavigate();

  const handleRetour = (event) => {
    event.preventDefault();
    const navigateTo = link ? link : -1;
    navigate(navigateTo);
  };
  return (
    <div className="return-btn">
      <button className="btn__shell" onClick={handleRetour}>
        <KeyboardReturnSharpIcon />
        Retour
      </button>
    </div>
  );
};

export default ReturnBtn;
