import React from 'react';
import CLdevisLayout from '../../../../components/layout/CLdevisLayout';
import CLfidMailingCourrierForm from '../../../../components/ui/forms/communication/CLfidMailingCourrierForm';

const CLmailing = () => {
  return (
    <CLdevisLayout
      title="Mailing courrier fidélité (format 10 x 21cm)"
      alert="la demande doit être faite au minimum 5 semaines avant le début de l'opération"
    >
      <CLfidMailingCourrierForm />
    </CLdevisLayout>
  );
};

export default CLmailing;
