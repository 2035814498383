import React from 'react';
import './style.scss';

const BDCidentificationTable = ({ product }) => {
  return (
    <div className="condition-table-wrap">
      <table className="condition-table">
        <thead>
          <tr className="condition-table__head">
            <th className="condition-table__head-row">EAN</th>
            <th className="condition-table__head-row">Référence</th>
          </tr>
        </thead>
        <tbody>
          <tr className="condition-table__body">
            <td className="condition-table__body-row">
              {product.pbd_gencod}
            </td>
            <td className="condition-table__body-row">
              {product.pbd_reference}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BDCidentificationTable;
