import React from 'react';
import SpinLoader from '../../loader/SpinLoader';
import './style.scss';

/**
 * Couleurs de boutons possibles
 */
const colors = {
  'color-primary': true,
  'color-secondary': true,
  'color-button': true,
  'color-button-variant': true,
  'color-danger': true,
  'color-neutral': true,
};

const defaultColor = 'color-button';

/** 
 * Basic button component
 * @param libelle le texte à afficher
 * @param onClick la fonction à executer sur un evenement click
 * @param color la color du bouton (choix entre :
 * primary-btn secondary-btn danger-btn excel-btn excel-variant-btn
 * @icon l'icon à coté du texte
 * @type le type du bouton
 * @rest le reste des propriétés
)
*/
const Button = ({
  libelle,
  onClick,
  loading,
  color = defaultColor,
  icon,
  type = 'button',
  disabled = false,
  ...rest
}) => {
  // Vérification sur le libelle et le onClick
  if (!libelle) return null;
  // Vérification de l'appartenance du libelle au tableau
  if (!colors[color]) color = defaultColor;

  return (
    <button
      type={type}
      className={['button', loading ? 'disabled' : '', color].join(
        ' '
      )}
      onClick={onClick}
      {...rest}
      disabled={loading || disabled}
    >
      {loading && <SpinLoader />}
      <span className={`${loading ? 'loading' : ''} `}>
        {libelle} {icon && icon}
      </span>
    </button>
  );
};

export default Button;
