import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getArticles } from '../services/fournisseurs_produits_apis';

export const fetchArticles = createAsyncThunk(
  'article/fetchArticles',
  async (params) => {
    try {
      const { mag_code, filtres, page } = params;
      const response = await getArticles(mag_code, filtres, page);
      return response;
    } catch (error) {
      throw new Error(
        "Une erreur s'est produite lors de la récupération des articles."
      );
    }
  }
);

const initialState = {
  loading: true, // Indicateur de chargement
  articles: [], // Liste des articles
  filtres: {
    // Filtres de recherche
    con_code: '', // Code de condition
    art_des: '', // Designation de l'article
    art_reference: '', // Référence de l'article
    art_marque: '', // Marque de l'article
    art_gencod: '', // Code générique de l'article
    art_nouveaute: '', // Indicateur de nouveauté de l'article
    ac_code: '', // Indicateur de préconisation
  },
  page: 1, // Numéro de la page actuelle
  last_page: 1, // Numéro de la dernière page
};

const articleSlice = createSlice({
  name: 'article', // Nom du slice
  initialState, // État initial
  reducers: {
    // Action pour modifier le filtre
    setFiltres: (state, action) => {
      state.filtres = action.payload;
    },
    // Action pour passer à la page suivante
    pageNext: (state) => {
      if (state.last_page > state.page) state.page++; // Vérifie si la page actuelle n'est pas la dernière
    },
    // Action pour passer à la page précédente
    pagePrev: (state) => {
      if (state.page > 1) state.page--; // Vérifie si la page actuelle n'est pas la première
    },
    resetPage: (state) => {
      state.page = initialState.page;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchArticles.pending, (state) => {
        state.loading = true;
      })
      // Action lorsque la récupération des articles est terminée avec succès
      .addCase(fetchArticles.fulfilled, (state, action) => {
        state.articles = action.payload.data; // Met à jour la liste des articles avec les données reçues
        state.last_page = action.payload.last_page; // Met à jour le numéro de la dernière page
        state.page = action.payload.current_page; // Met à jour le numéro de la page actuelle
        state.loading = false; // Met à jour l'indicateur de chargement
      })
      // Action lorsque la récupération des articles a échoué
      .addCase(fetchArticles.rejected, (state) => {
        state.loading = false; // Met à jour l'indicateur de chargement
      });
  },
});

export const { pageNext, pagePrev, resetPage, setFiltres } =
  articleSlice.actions;
export default articleSlice.reducer;
