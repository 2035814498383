import {useOutletContext} from "react-router-dom";
import {useMemo} from "react";
import React from "react";
import {PATH} from "../../../../config/constants";
import MDGColorCardLink from "../../../../components/ui/cards/_fournisseurs_produits/MDGCard";
import "./style.scss";
const MDGselection = (factory, deps) => {
    const {mdgList}= useOutletContext();
    const optionList = useMemo(() => {
        // on vérifie s'il y a des telechargements à afficher dans la rubriques
        const filteredMdg = mdgList.filter(
            (mdg) => mdg.mdg_rubriques.length
        );

        return filteredMdg.map((mdg) => ({
            to: `${PATH.MDG_PAGE}/${mdg.dsr_code}`,
            text: mdg.mdg_nom,
            className:`mdg-option-dsr-${mdg.dsr_code}`,

        }));

    },  [mdgList]);



    return (
        <div className="mdg-page-selection">
        <div> <h3 className="mdg-page-selection-title">Vos Marques de Groupe</h3>
        <div className="mdg-page-selection-list">
            {optionList.map((option)=> (
                <MDGColorCardLink
                    key={option.to}
                    to={option.to}
                    className={option.className}
                >
                    {option.text}
                </MDGColorCardLink>
            ))}

        </div>


        </div>
        </div>
    );
}
export default MDGselection;