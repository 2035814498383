import React from 'react';
import './style.scss';
import PhoneColorIcon from '../../../icons/PhoneColorIcon';
import PCIcon from '../../../icons/PCIcon';
import PDFIcon from '../../../icons/PDFIcon';
import CellPhoneIcon from '../../../icons/CellPhoneIcon';
import MapIcon from '../../../icons/MapIcon';
import AtIcon from '../../../icons/AtIcon';
import { formatPhoneNumber } from '../../../../../utils/formats';
import UserIcon from '../../../icons/UserIcon';
import {DOMAIN} from "../../../../../config/env";

const AEprestataireCard = ({ prestation }) => {
  if (!!!prestation) return null;
  return (
    <div className="ae-prestataire-card">
      <header className="ae-prestataire-card__header">
        <h3 className="ae-prestataire-card-title">
          {prestation.pre_nom}
        </h3>
      </header>
      <section className="ae-prestataire-card__section">
        <h4 className="ae-prestataire-card-sub-title">
          Informations
        </h4>
        <ul className="ae-prestataire-card-info">
          <li>
            <UserIcon />
            &nbsp;
            {prestation.pre_responsable}
          </li>
          {prestation.pre_tel && (
            <li>
              <PhoneColorIcon />
              &nbsp;{formatPhoneNumber(prestation.pre_tel)}
            </li>
          )}

          {prestation.pre_port && (
            <li>
              <CellPhoneIcon />
              &nbsp;
              {formatPhoneNumber(prestation.pre_port)}
            </li>
          )}
          {prestation.pre_mail && (
            <li>
              <AtIcon />
              &nbsp;
              <a href={`mailto:${prestation.pre_mail}`}>
                {prestation.pre_mail}
              </a>
            </li>
          )}
          {prestation.pre_site && (
            <li>
              <PCIcon />
              &nbsp;
              <a href={prestation.pre_site}>
                {prestation.pre_site}
              </a>
            </li>
          )}
          {prestation.pre_adresse && (
            <li>
              <MapIcon />
              &nbsp;
              {prestation.pre_adresse}
            </li>
          )}
        </ul>
      </section>
      <section className="ae-prestataire-card__section">
        <h4 className="ae-prestataire-card-sub-title">
          Famille(s) - Produit(s)
        </h4>
        <ul className="ae-prestataire-card-info">
          <li>{prestation.pre_famille_produit}</li>
        </ul>
      </section>
      <section className="ae-prestataire-card__section">
        <h4 className="ae-prestataire-card-sub-title">
          Documentations
        </h4>
        <ul className="ae-prestataire-card-info filepdf">
          {prestation.files.map((file) => (
            <>
              <li>
                <a
                  href={`${DOMAIN.URL}/_fichier/_prestataire/${file.pre_file_nom}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <PDFIcon />
                </a>
              </li>
            </>
          ))}
        </ul>
      </section>
    </div>
  );
};

export default AEprestataireCard;
