import React from 'react';
import CLdevisLayout from '../../../../components/layout/CLdevisLayout';
import CLdistriSpeForm from '../../../../components/ui/forms/communication/CLdistriSpeForm';

const CLdistriSpe = () => {
  return (
    <CLdevisLayout
      title="Distribution spécifique"
      alert="la demande doit être effectuée
            2 semaines minimum avant la distribution"
    >
      <CLdistriSpeForm />
    </CLdevisLayout>
  );
};

export default CLdistriSpe;
