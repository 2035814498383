import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAuth from '../../../../../hooks/useAuth';
import { getPlanoDetail } from '../../../../../services/fournisseurs_produits_apis';
import NormalTable from '../../../../../components/ui/tables/NormalTable';
import PlanoCard from './../../../../../components/ui/cards/_fournisseurs_produits/PlanoCard';
import Loader from '../../../../../components/ui/loader/Loader';
import ReturnBtn from '../../../../../components/ui/button/ReturnBtn';

const PlanoDetail = () => {
  const { magasin } = useAuth();
  const [plano, setPlano] = useState([]);
  const [loading, setLoading] = useState(true);
  const { pga_code } = useParams();
  const fetchPlano = useCallback(async () => {
    try {
      const data = await getPlanoDetail(magasin.mag_code, pga_code);
      setPlano(data);
      setLoading(false);
    } catch (err) {
      console.log(err + 'pga:' + pga_code);
    }
  }, [magasin.mag_code, pga_code]);
  const columns = useMemo(
    () => [
      {
        Header: 'Gencod',
        accessor: 'article.art_gencod',
        filter: 'includes',
      },
      {
        Header: 'Designation',
        accessor: 'article.art_des',
        filter: 'includes',
      },
      {
        Header: 'Référence',
        accessor: 'article.art_reference',
        filter: 'includes',
      },
      {
        Header: 'Fournisseur',
        accessor: 'con_nom',
        filter: 'includes',
      },
      {
        Header: 'PA HT B',
        accessor: 'article.art_brut',
        Cell: ({ value }) =>
          value
            ? value.toLocaleString('fr-FR', {
                style: 'currency',
                currency: 'EUR',
              })
            : '',
        cellClassName: 'normal-table-numeric',
      },
      {
        Header: 'PA HT Net',
        accessor: 'article.art_net',
        Cell: ({ value }) =>
          value
            ? value.toLocaleString('fr-FR', {
                style: 'currency',
                currency: 'EUR',
              })
            : '',
      },
      {
        Header: 'PVS',
        accessor: 'article.art_pvs',
        Cell: ({ value }) =>
          value
            ? value.toLocaleString('fr-FR', {
                style: 'currency',
                currency: 'EUR',
              })
            : '',
      },
      { Header: 'Quantité cmd', accessor: 'pgd_qte' },
      { Header: 'Qté Lin.', accessor: 'pgd_qte_lineaire' },
      {
        Header: 'Valeur ACHAT',
        accessor: (row) =>
          row.article ? row.pgd_qte * row.article.art_net : 0,
        Cell: ({ value }) =>
          value.toLocaleString('fr-FR', {
            style: 'currency',
            currency: 'EUR',
          }),
      },
      {
        Header: 'Valeur VENTE',
        accessor: (row) =>
          row.article ? row.pgd_qte * row.article.art_pvs : 0,
        Cell: ({ value }) =>
          value.toLocaleString('fr-FR', {
            style: 'currency',
            currency: 'EUR',
          }),
      },
    ],
    [] // Assurez-vous de passer un tableau vide ici pour les dépendances
  );
  React.useEffect(() => {
    fetchPlano();
  }, [fetchPlano]);
  if (loading) return <Loader />;
  return (
    <div className="plano-detail">
      <ReturnBtn />
      <div className="plano-info">
        <PlanoCard plano={plano[0]} />
      </div>
      <div className="article-table">
        <NormalTable
          columns={columns}
          data={plano[0].plano_details}
        />
      </div>
    </div>
  );
};
export default PlanoDetail;
