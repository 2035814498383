import React from 'react';
import LoaderComponent from '../LoaderComponent';
import './style.scss';

const Loader = () => {
  return (
    <>
      {/* <div className="about">
        <a
          className="bg_links social portfolio"
          href="https://www.rafaelalucas.com"
          target="_blank"
          rel="noreferrer"
        >
          <span className="icon"></span>
        </a>
        <a
          className="bg_links social dribbble"
          href="https://dribbble.com/rafaelalucas"
          target="_blank"
          rel="noreferrer"
        >
          <span className="icon"></span>
        </a>
        <a
          className="bg_links social linkedin"
          href="https://www.linkedin.com/in/rafaelalucas/"
          target="_blank"
          rel="noreferrer"
        >
          <span className="icon"></span>
        </a>
        <a className="bg_links logo"></a>
      </div> */}

      <div className="loader-page">
        <LoaderComponent />
      </div>
    </>
  );
};

export default Loader;
