import React from 'react';
import SearchIcon from '../../icons/SearchIcon';
import './style.scss';

const SearchBarInput = ({ onChange, ...rest }) => {
  return (
    <div className="search-bar-input-wrapper">
      <div className="search-bar-icon">
        <SearchIcon width={'1em'} height={'1em'} />
      </div>
      <input
        {...rest}
        type="text"
        onChange={onChange}
        className="search-bar-input"
      />
    </div>
  );
};

export default SearchBarInput;
