import React from 'react';
import anime from 'animejs';
import './style.scss';

const Path = React.forwardRef((props, ref) => (
  <path
    ref={ref}
    fill="transparent"
    strokeWidth="3"
    stroke="currentColor"
    strokeLinecap="round"
    {...props}
  />
));

const animatePath = (target, d, opacity) => {
  anime({
    targets: target,
    d: [{ value: d }],
    opacity: [{ value: opacity }],
    duration: 500,
    easing: 'easeInOutCirc',
  });
};

const MenuToggleButton = ({ toggle = () => {} }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const topRef = React.useRef(null);
  const middleRef = React.useRef(null);
  const bottomRef = React.useRef(null);

  React.useEffect(() => {
    const topTarget = topRef.current;
    const middleTarget = middleRef.current;
    const bottomTarget = bottomRef.current;

    if (isOpen) {
      animatePath(topTarget, 'M 3 16.5 L 17 2.5', 1);
      animatePath(middleTarget, 'M 2 9.423 L 20 9.423', 0);
      animatePath(bottomTarget, 'M 3 2.5 L 17 16.346', 1);
    } else {
      animatePath(topTarget, 'M 2 2.5 L 20 2.5', 1);
      animatePath(middleTarget, 'M 2 9.423 L 20 9.423', 1);
      animatePath(bottomTarget, 'M 2 16.346 L 20 16.346', 1);
    }
  }, [isOpen]);

  return (
    <button
      className="menu-toggle-btn"
      onClick={() => {
        toggle();
        setIsOpen(!isOpen);
      }}
    >
      <svg width="23" height="23" viewBox="0 0 23 23">
        <Path d="M 2 2.5 L 20 2.5" ref={topRef} />
        <Path d="M 2 9.423 L 20 9.423" ref={middleRef} />
        <Path d="M 2 16.346 L 20 16.346" ref={bottomRef} />
      </svg>
    </button>
  );
};
export default MenuToggleButton;
