import React from 'react';
import './style.scss';
import { Link } from 'react-router-dom';

const FillLink = ({
  libelle,
  sublibelle,
  className,
  to,
  icon,
  rest,
}) => {
  if (!libelle) return null;
  return (
    <Link className={className + ' filled-link'} to={to} {...rest}>
      {icon}
      <span className="libelle">
        {libelle}
        <span className="sublibelle">{sublibelle}</span>
      </span>
    </Link>
  );
};

export default FillLink;
