import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import LoaderComponent from '../../../components/ui/loader/LoaderComponent';
import useAuth from '../../../hooks/useAuth';
import {
  getFournisseursCommandList,
  getFournisseursList,
  getProspectusList,
} from '../../../services/communication_apis';
import BDCLayout from './../../../components/layout/BDCLayout';

const BDC = () => {
  const [loading, setLoading] = useState(true); // Indicate the call has not finished. Default to true

  const [prospectusList, setProspectusList] = useState({}); // Prospectus list available for the user
  const [fournisseursList, setFournisseursList] = useState({}); // Fournisseurs list
  const [fournisseursCommandsList, setFournisseursCommandsList] =
    useState({}); // Fournisseurs list whith user commands only
  const { magasin } = useAuth(); // Magasin code used
  const codeMagasin = magasin.mag_code;

  const handleRefreshFournisseursCommand = () => {
    if (codeMagasin) {
      try {
        getFournisseursCommandList(codeMagasin).then((res) => {
          setFournisseursCommandsList(res.data);
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  React.useEffect(() => {
    const fetchData = async () => {
      if (codeMagasin) {
        try {
          await getProspectusList(codeMagasin).then((res) => {
            setProspectusList(res.data);
          });
          await getFournisseursCommandList(codeMagasin).then(
            (res) => {
              setFournisseursCommandsList(res.data);
            }
          );
          await getFournisseursList(codeMagasin).then((res) => {
            setFournisseursList(res.data);
          });
        } catch (err) {
          console.log(err);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, [codeMagasin]);

  return (
    <BDCLayout>
      {loading ? (
        <LoaderComponent />
      ) : (
        <Outlet
          context={{
            prospectusList,
            fournisseursList,
            fournisseursCommandsList,
            setFournisseursCommandsList,
            handleRefreshFournisseursCommand,
          }}
        />
      )}
    </BDCLayout>
  );
};

export default BDC;
