import React from 'react';
import './style.scss';
import AuthLayout from '../../../components/layout/AuthentificationLayout';

const Maintenance = () => {
  return (
    <AuthLayout>
      <span className="message-maintenance">
        Nous sommes actuellement en maintenance et serons de retour le
        26 juillet. <br /> Merci de votre compréhension.
      </span>
    </AuthLayout>
  );
};

export default Maintenance;
