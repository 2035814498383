import useAuth from '../../../../hooks/useAuth';
import React, { useState } from 'react';

import { getOpeAccueil } from '../../../../services/plan_com_apis';
import './style.scss';
import { DOMAIN } from '../../../../config/env';
import { PATH, RESSOURCE } from '../../../../config/constants';

const PlanComAccueilCard = () => {
  const { magasin } = useAuth(); // magasins code
  const [operations, setOperations] = useState([]); // Liste des operations
  React.useEffect(() => {
    const fetchData = async () => {
      const res = await getOpeAccueil(magasin.mag_code);
      setOperations(res);
    };

    fetchData();
  }, [magasin.mag_code]);

  return (
    <div className="accueil-ope-card">
      <div className="accueil-ope">
        <header className="accueil-ope-header">
          <h3>COM DU MOMENT</h3>
        </header>
        {operations.EnCeMoment && (
          <div className="accueil-ope-img">
            <a
              href={
                PATH.PLAN_DE_COM.replace(
                  ':mag_code',
                  magasin.mag_code
                ).replace(':mag_token', magasin.mag_token) +
                '&ssdo=op&co_code=' +
                operations.EnCeMoment.co_code
              }
              title={operations.EnCeMoment.co_libelle}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={
                  DOMAIN.URL +
                  '/' +
                  RESSOURCE.PLAN_DE_COM_COUV +
                  operations.EnCeMoment.co_couv
                }
                alt=""
              />
            </a>
          </div>
        )}
      </div>
      <div className="accueil-ope">
        <header className="accueil-ope-header">
          <h3>COM A VENIR</h3>
        </header>
        {operations.AVenir && (
          <div className="accueil-ope-img">
            <a
              href={
                PATH.PLAN_DE_COM.replace(
                  ':mag_code',
                  magasin.mag_code
                ).replace(':mag_token', magasin.mag_token) +
                '&ssdo=op&co_code=' +
                operations.AVenir.co_code
              }
              title={operations.AVenir.co_libelle}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={
                  DOMAIN.URL +
                  '/' +
                  RESSOURCE.PLAN_DE_COM_COUV +
                  operations.AVenir.co_couv
                }
                alt=""
              />
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlanComAccueilCard;
