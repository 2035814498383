import React from 'react';
import ReturnBtn from '../../ui/button/ReturnBtn';
import './style.scss';

const CLdevisLayout = ({ children, title, alert }) => {
  return (
    <>
      <div className="cl-devis-layout">
        <div className="cl-devis">
          <ReturnBtn />
          <header>
            <h3 className="cl-devis__title">{title}</h3>
          </header>
          <div className="cl-devis__main">
            <p className="warning">
              <span> ATTENTION : </span> {alert}
            </p>
            <div className="cl-devis__form">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CLdevisLayout;
