import React, { useMemo } from 'react';
import MergedRowTable from '../../MergedRowTable';

const PlanoFournisseurTable = ({ planos }) => {
  // Définition des colonnes de la table en utilisant useMemo pour la performance
  const columns = useMemo(
    () => [
      {
        Header: 'Rayon', // Titre de la colonne
        accessor: 'ray_lib', // Clé d'accès aux données de cette colonne
        mergeOn: 'ray_code', // Propriété utilisée pour fusionner les cellules de cette colonne
      },
      {
        Header: 'Famille', // Titre de la colonne
        accessor: 'fam_lib', // Clé d'accès aux données de cette colonne
        mergeOn: 'fam_code', // Propriété utilisée pour fusionner les cellules de cette colonne
      },
      {
        Header: 'Plano Gamme', // Titre de la colonne
        accessor: 'pga_lib', // Clé d'accès aux données de cette colonne
      },
    ],
    []
  );

  return (
    <MergedRowTable
      data={planos} // Données à afficher dans la table
      columns={columns} // Colonnes définies précédemment
    />
  );
};

export default PlanoFournisseurTable;
