import React from 'react';
import PRFupdatePasswordForm from '../../components/ui/forms/profile/PRFupdatePasswordForm';
import './style.scss';
import GeneralLayout from '../../components/layout/GeneralLayout';
import PRFprofilForm from '../../components/ui/forms/profile/PRFprofilForm';

const Profile = () => {
  return (
    <GeneralLayout>
      <div className="profile">
        <div className="profile-forms">
          <PRFprofilForm />
          <PRFupdatePasswordForm />
        </div>
      </div>
    </GeneralLayout>
  );
};

export default Profile;
