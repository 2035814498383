import React from 'react';

const Cell = ({ cell }) => {
  const mergeAttribute = cell.column.mergeOn; // Attribut utilisé pour le merge
  const cellRowValue = cell.row.original; // Objet de la ligne concerné

  if (mergeAttribute) {
    return (
      <>
        {cellRowValue[mergeAttribute] && (
          <td
            {...cell.getCellProps()}
            rowSpan={cellRowValue.rowspan[mergeAttribute]}
            className={cell.column.className}
          >
            {cell.render('Cell')}
          </td>
        )}
      </>
    );
  }
  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
};

export default Cell;
