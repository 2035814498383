import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import BDCprospectusCard from '../../../../components/ui/cards/communication/BDCprospectusCard';
import LoaderComponent from '../../../../components/ui/loader/LoaderComponent';
import { getPeriodes } from '../../../../services/communication_apis';
import './style.scss';

/**
 * Screen displaying the list of available prospectus for the user
 */
const BDCselectionProspectus = () => {
  const [loading, setLoading] = useState(true); // Indicate the call has not finished. Default to true

  const { prospectusList } = useOutletContext(); // Prospectus list of the user
  const [periodes, setPeriodes] = useState({}); // List of prospectus periodes

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        await getPeriodes().then((res) => {
          setPeriodes(res.data);
        });
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [prospectusList]);

  return (
    <>
      {loading ? (
        <LoaderComponent />
      ) : (
        <>
          <div className="selection-prospectus__container">
            {prospectusList.length >= 1 ? (
              periodes.length >= 1 &&
              periodes.map((periode) => {
                let prospectusFiltered = prospectusList.filter(
                  (prospectus) => {
                    return (
                      prospectus.ps_cperiode === periode.ps_cperiode
                    );
                  }
                );

                return (
                  <React.Fragment key={periode.ps_cperiode}>
                    {prospectusFiltered.length >= 1 && (
                      <>
                        <div>
                          <h3>{periode.ps_libelle}</h3>
                          <div className="prospectus__card_list">
                            {prospectusFiltered.map(
                              (prospectus, i) => {
                                return (
                                  <BDCprospectusCard
                                    key={
                                      prospectus.psp_libelle +
                                      prospectus.psp_cprospectus
                                    }
                                    prospectus={prospectus}
                                  />
                                );
                              }
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </React.Fragment>
                );
              })
            ) : (
              <>Aucuns prospectus </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default BDCselectionProspectus;
