import React from 'react';
import banniere from './banniere-cl.jpg';
import PageBannerLayout from '../PageBannerLayout';

const CLLayout = ({ children }) => {
  return (
    <PageBannerLayout imageHeader={banniere}>
      {children}
    </PageBannerLayout>
  );
};

export default CLLayout;
