import React from 'react';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import './style.scss';
import { formatDate } from '../../../../../utils/dates';

const BDCfournisseurCommandCard = ({ fournisseur }) => {
  return (
    <>
      <div className="fournisseur-card">
        <div className="fournisseur-card__info">
          <h2> {fournisseur.con_nom} </h2>
          <div className="fournisseur-card__info">
            <p className="fournisseur-adresse">
              {fournisseur.adr_adresse2 !== '0'
                ? fournisseur.adr_adresse2 + ', '
                : ''}
              {fournisseur.adr_adresse1}&nbsp;{fournisseur.adr_cp}{' '}
              {fournisseur.adr_ville}
            </p>
            <div className="fournisseur-contact">
              <ul>
                <li>
                  <MailOutlinedIcon /> {fournisseur.adr_mail1}
                </li>
                <li>
                  <PhoneOutlinedIcon /> {fournisseur.adr_tel1}
                </li>
              </ul>
            </div>
            <div className="fournisseur-command">
              <h2> Pour commande direct fournisseur </h2>
              <div>
                <ul>
                  <li>
                    <span> Dates de commandes : </span>
                    {fournisseur.psp_limitecode === 1 ? (
                      <>
                        commander avant le{' '}
                        {formatDate(fournisseur.psp_limitecmd1)}
                      </>
                    ) : (
                      <>
                        commander du{' '}
                        {formatDate(fournisseur.psp_limitecmd0)} au
                        {formatDate(fournisseur.psp_limitecmd1)}
                      </>
                    )}
                    <br />
                  </li>
                  <li>
                    {parseInt(fournisseur.psp_franco) > 0 && (
                      <>
                        <span> Franco : </span>
                        {fournisseur.psp_franco}
                      </>
                    )}
                  </li>
                  <li>
                    {parseInt(fournisseur.psp_frais_port) > 0 && (
                      <>
                        <span> Frais port : </span>
                        {fournisseur.psp_franco}
                      </>
                    )}
                  </li>
                  <li>
                    <span> Date validité promo : </span>
                    {formatDate(fournisseur.psp_validite)}
                  </li>
                  <li className="text-forward">
                    {fournisseur.psp_limitecode === 1 ? (
                      <>
                        Commander avant le{' '}
                        {formatDate(fournisseur.psp_limitecmd1)}
                      </>
                    ) : (
                      <>
                        Commander du{' '}
                        {formatDate(fournisseur.psp_limitecmd0)} au{' '}
                        {formatDate(fournisseur.psp_limitecmd1)}
                      </>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="fournisseur-card__action"></div>
      </div>
    </>
  );
};

export default BDCfournisseurCommandCard;
