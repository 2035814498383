import anime from 'animejs';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
import ArrowRightIcon from '../../icons/ArrowRightIcon';

const TextCard = ({ libelle, to }) => {
  const cardRef = useRef(null); // Référence de la carte
  let animationRef = useRef(null); // Référence de l'animation en cours

  const handleMouseEnter = () => {
    // Si aucune animation n'est en cours
    if (!animationRef.current) {
      // Commencer une nouvelle animation et la sauvegarder dans animationRef
      animationRef.current = anime({
        targets: cardRef.current,
        width: '210%',
        height: '210%',
        easing: 'linear',
        duration: 250,
        // Réinitialiser animationRef à null lorsque l'animation est terminée
        complete: function (anim) {
          animationRef.current = null;
        },
      });
    }
  };

  const handleMouseLeave = () => {
    // Si une animation est en cours
    if (animationRef.current) {
      // Interrompre l'animation en cours
      animationRef.current.pause();
      // Commencer une nouvelle animation pour revenir à l'échelle originale
      anime({
        targets: cardRef.current,
        width: '300%',
        height: '300%',
        easing: 'linear',
        duration: 250,
      });
      // Réinitialiser animationRef à null
      animationRef.current = null;
    } else {
      // Commencer une nouvelle animation pour revenir à l'échelle originale
      anime({
        targets: cardRef.current,
        width: '300%',
        height: '300%',
        easing: 'linear',
        duration: 250,
      });
    }
  };
  return (
    <Link
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      to={to}
      className="select-info-card"
    >
      {libelle}
      <div ref={cardRef} className="card-background"></div>
      <span className="card-icon">
        <ArrowRightIcon width={15} height={15} />
      </span>
    </Link>
  );
};

export default TextCard;
