import React,{useState} from "react";
import {getContacts} from "../../../services/apis";
import Loader from "../../../components/ui/loader/Loader";
import ContactCard from "../../../components/ui/cards/contact";
import PhoneColorIcon from "../../../components/ui/icons/PhoneColorIcon";
import "./style.scss";
import GeneralLayout from "../../../components/layout/GeneralLayout";

const ContactsPage = () =>{
    const [contactsList, setContactsList] = useState({});
    const [loading, setLoading] = useState(true);
    /* Réucpération des contacts */
    const fetchContacts = React.useCallback(async () => {
        try {
            const data = await getContacts();
            setContactsList(data);
        } catch (err) {
            console.log(err);
        }

    });
    React.useEffect(() => {
        const fetchData = async () => {
            try {
                await fetchContacts();
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    });
    if (loading) return (
        <GeneralLayout>
            <Loader />
        </GeneralLayout>
    );
   if (!!!contactsList.length) return <GeneralLayout>Pas de données</GeneralLayout>;


    return (<GeneralLayout>
        <div className="contact-page"><h3 className="contact-page-title">Liste des contacts</h3>
            <div className="contact-list">
                {contactsList.map((contactService)=> (
                    <div className="service-card">
                        <div className="service-card-gauche">
                            <ul>
                                <li className="service-title">{contactService.cct_serv_titre}</li>
                                {contactService.cct_serv_tel && ( <li><PhoneColorIcon /> {contactService.cct_serv_tel}</li>)}
                            </ul>
                        </div>
                        <div className="service-card-droite">
                            <ul>
                                {contactService.contacts.map((contact) => (
                                    <li><ContactCard contact={contact}/></li>
                                ))
                                }
                            </ul>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </GeneralLayout>);
};
export default ContactsPage;