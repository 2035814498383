import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import './style.scss';
import StarIcon from '../../../../../components/ui/icons/StarIcon';
import Select from '../../../../../components/ui/input/Select';
import { DOMAIN } from '../../../../../config/env';
import { PATH, RESSOURCE } from '../../../../../config/constants';
import { Link } from 'react-router-dom';
import ReturnBtn from '../../../../../components/ui/button/ReturnBtn';

const PrecoFournisseur = () => {
  const { idMetier, metiers } = useSelector((state) => state.metier);
  const [selectedRayon, setSelectedRayon] = useState(-1);

  const data = useMemo(() => {
    if (idMetier) {
      let filterMet = metiers.filter(
        (metier) =>
          // Filtre sur le metier et le rayon
          metier.met_code === idMetier
      );
      const filterRay = filterMet[0].rayons.filter((rayon) =>
        parseInt(selectedRayon) !== -1
          ? parseInt(rayon.ray_code) === parseInt(selectedRayon)
          : rayon
      );

      return { ...filterMet, rayons: filterRay };
    }
  }, [idMetier, metiers, selectedRayon]);

  const rayList = useMemo(
    () => metiers.filter((metier) => metier.met_code === idMetier),
    [metiers, idMetier]
  );

  if (!data) return <>Pas de préconisation, essayez un autre univers <ReturnBtn /></>;
  return (
    <div className="preco-four">
      <ReturnBtn />
      <div className="preco-four-table-overlay">
        <div className="filter">
          <Select
            label="Rayon"
            value={selectedRayon}
            onChange={(event) => setSelectedRayon(event.target.value)}
          >
            <option value={-1}>--- TOUS LES RAYONS ---</option>
            {rayList[0].rayons.map((rayon) => (
              <option key={rayon.ray_code} value={rayon.ray_code}>
                {rayon.ray_lib}
              </option>
            ))}
          </Select>
        </div>
        <div className="preco-four-table-wrap">
          <table className="preco-four-table">
            <tbody>
              {data.rayons.map((rayon, k) => (
                <>
                  <tr className="title-row centered-row">
                    <td colSpan={5}>{rayon.ray_lib}</td>
                  </tr>
                  <tr className="title-row">
                    <td>famille</td>
                    <td>sous famille</td>
                    <td className="low-row">
                      <span>
                        leader
                        <StarIcon /><StarIcon /><StarIcon />
                      </span>
                    </td>
                    <td className="low-row">
                      <span>
                        challenger
                        <StarIcon /><StarIcon />
                      </span>
                    </td>
                    <td className="low-row">
                      <span>
                        complementaire
                        <StarIcon />
                      </span>
                    </td>
                  </tr>
                  {rayon.familles.map((famille, i) => (
                    <>
                      {famille.sfamilles.map((sfamille, j) => (
                        <tr
                          className={
                            k % 2 === 0
                              ? i % 2 === 0 && 'color-row'
                              : i % 2 !== 0 && 'color-row'
                          }
                        >
                          {j === 0 && (
                            <td rowSpan={famille.sfamilles.length}>
                              {famille.fam_lib}
                            </td>
                          )}
                          <td>{sfamille.sfam_lib}</td>
                          <td className="centered-row">
                            {sfamille.clas_s_fam_types[0] ? (
                              <>
                                {sfamille.clas_s_fam_types[0].clas_positions.map(
                                  (challenger) => (
                                    <Link
                                      to={PATH.CONTRAT_DETAILS.replace(
                                        ':con_code',
                                        challenger.contrat?.con_code
                                      )}
                                    >
                                      {challenger.contrat?.con_nom +
                                        '\n'}
                                      {challenger.contrat?.con_mdg ===
                                        -1 && (
                                        <img
                                          className="img-mdg"
                                          src={`${DOMAIN.URL}/${RESSOURCE.MDG_ICON}`}
                                          alt=""
                                        />
                                      )}
                                    </Link>
                                  )
                                )}
                              </>
                            ) : (
                              <>-</>
                            )}
                          </td>
                          <td className="centered-row">
                            {sfamille.clas_s_fam_types[1] ? (
                              <>
                                {sfamille.clas_s_fam_types[1].clas_positions.map(
                                  (challenger) => (
                                    <Link
                                      to={PATH.CONTRAT_DETAILS.replace(
                                        ':con_code',
                                        challenger.contrat?.con_code
                                      )}
                                    >
                                      {challenger.contrat?.con_nom +
                                        '\n'}
                                      {challenger.contrat?.con_mdg ===
                                        -1 && (
                                        <img
                                          className="img-mdg"
                                          src={`${DOMAIN.URL}/${RESSOURCE.MDG_ICON}`}
                                          alt=""
                                        />
                                      )}
                                    </Link>
                                  )
                                )}
                              </>
                            ) : (
                              <>-</>
                            )}
                          </td>
                          <td className="centered-row">
                            {sfamille.clas_s_fam_types[2] ? (
                              <>
                                {sfamille.clas_s_fam_types[2].clas_positions.map(
                                  (challenger) => (
                                    <Link
                                      to={PATH.CONTRAT_DETAILS.replace(
                                        ':con_code',
                                        challenger.contrat?.con_code
                                      )}
                                    >
                                      {challenger.contrat?.con_nom +
                                        '\n'}
                                      {challenger.contrat?.con_mdg ===
                                        -1 && (
                                        <img
                                          className="img-mdg"
                                          src={`${DOMAIN.URL}/${RESSOURCE.MDG_ICON}`}
                                          alt=""
                                        />
                                      )}
                                    </Link>
                                  )
                                )}
                              </>
                            ) : (
                              <>-</>
                            )}
                          </td>
                        </tr>
                      ))}
                    </>
                  ))}
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PrecoFournisseur;
