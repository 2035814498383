import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getClassification } from './../services/fournisseurs_produits_apis';

export const fetchMetiers = createAsyncThunk(
  'metier/fetchMetiers',
  async (mag_code) => {
    try {
      const response = await getClassification(mag_code);
      return response;
    } catch (error) {
      throw new Error(
        "Une erreur s'est produite lors de la récupération des métiers."
      );
    }
  }
);

const initialState = {
  loading: true,
  metiers: [],
  idMetier: null,
};

const metierSlice = createSlice({
  name: 'metier',
  initialState,
  reducers: {
    setIdMetier: (state, action) => {
      state.idMetier = action.payload;
    },
    resetIdMetier: (state) => {
      state.idMetier = initialState.idMetier;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMetiers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchMetiers.fulfilled, (state, action) => {
        state.loading = false;
        state.metiers = action.payload;
      })
      .addCase(fetchMetiers.rejected, (state) => {
        state.loading = false;
        // Gérer les erreurs si nécessaire
      });
  },
});

export const { setIdMetier, resetIdMetier } = metierSlice.actions;
export default metierSlice.reducer;
