import React from 'react';
import ReactDOM from 'react-dom';
import CancelIcon from '../../icons/CancelIcon';
import './style.scss';

/**
 * Couleurs de modal disponible
 */
const colors = {
  'color-primary': true,
  'color-danger': true,
};

/**
 * Modal basique.
 */
const Modal = ({ isShowing, hide, children, color }) => {
  const stopPropagation = (e) => e.stopPropagation(); // Empeche la propagation du click pour ne pas fermer le modal.

  // Vérification de l'appartenance de la couleur au tableau
  if (!colors[color]) color = 'color-primary';

  return (
    isShowing &&
    ReactDOM.createPortal(
      <>
        <div className="modal-overlay">
          <div className="modal-wrapper" onClick={hide}>
            <div
              className={['modal', color].join(' ')}
              onClick={stopPropagation}
            >
              <button
                type="button"
                className="modal-exit"
                onClick={hide}
              >
                <CancelIcon width={25} height={25} />
              </button>

              <div className="modal__body">{children}</div>
            </div>
          </div>
        </div>
      </>,
      document.body
    )
  );
};

export default Modal;
