import React from 'react';
import './style.scss';

const BDCExcelCommandButton = ({ fournisseur }) => {
  const prefix = 'https://api.inedis.fr';

  return (
    <>
      <a
        className="excel-command-btn"
        href={`${prefix}/command-excel/${fournisseur.bi_code}`}
        rel="noreferrer"
      >
        articles&nbsp;excel
      </a>
    </>
  );
};

export default BDCExcelCommandButton;
