import React, { useRef } from 'react';
import anime from 'animejs';
import './style.scss';
import { Link } from 'react-router-dom';

const PhotoTextCard = ({
  text,
  photo,
  onClick = () => {},
  link = '',
}) => {
  const cardRef = useRef(null); // Référence de la carte
  let animationRef = useRef(null); // Référence de l'animation en cours

  const handleMouseEnter = () => {
    // Si aucune animation n'est en cours
    if (!animationRef.current) {
      // Commencer une nouvelle animation et la sauvegarder dans animationRef
      animationRef.current = anime({
        targets: cardRef.current,
        scale: 1.1,
        duration: 300,
        easing: 'easeInOutQuad',
        direction: 'alternate',
        loop: 4,
        // Réinitialiser animationRef à null lorsque l'animation est terminée
        complete: function (anim) {
          animationRef.current = null;
        },
      });
    }
  };

  const handleMouseLeave = () => {
    // Si une animation est en cours
    if (animationRef.current) {
      // Interrompre l'animation en cours
      animationRef.current.pause();
      // Commencer une nouvelle animation pour revenir à l'échelle originale
      anime({
        targets: cardRef.current,
        scale: 1.0,
        duration: 300,
        easing: 'easeInOutQuad',
      });
      // Réinitialiser animationRef à null
      animationRef.current = null;
    }
  };

  return (
    <Link
      className="photo-text-card"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
      to={link}
    >
      <div className="photo-text-card__photo">
        <img ref={cardRef} src={photo} alt="" />
      </div>
      <div className="photo-text-card__text"> {text}</div>
    </Link>
  );
};

export default PhotoTextCard;
