import React, { useCallback, useMemo } from 'react';
import './style.scss';
import { useSelector } from 'react-redux';
import { METIER, PATH } from '../../../../config/constants';
import Drawer from '../../../ui/drawers/Drawer';
import PageBannerLayout from '../../PageBannerLayout';
import banniere from './banniere.png';
import { importImages } from '../../../../utils/image';

const UniversProduitsLayout = ({ children }) => {
  const { idMetier, metiers } = useSelector((state) => state.metier);
  // Stock les images du dossiers images dans un tableau
  const images = useMemo(() => {
    const imagesContext = require.context('./images', false);
    return importImages(imagesContext);
  }, []);

  const drawerLinks = useCallback(() => {
    const links = metiers.map((metier) => ({
      to: PATH.UP_SELECTION_METIER + '/' + metier.met_code,
      libelle: metier.met_lib,
      type: 'outlined',
      class: `${idMetier === metier.met_code ? 'selected-link' : ''}`,
    }));
    // Ajoutez de l'élément pour tous les univers
    const nouvelElement = {
      to: PATH.UP_SELECTION_METIER + '/' + METIER.METIER_ID_ALL,
      libelle: METIER.METIER_LIBELLE_ALL,
      type: 'outlined',
      class: `${
        idMetier === METIER.METIER_ID_ALL ? 'selected-link' : ''
      }`,
    };
    links.push(nouvelElement);
    return links;
  }, [idMetier, metiers]);

  return (
    <PageBannerLayout
      imageHeader={
        images.find((image) =>
          image.includes('metier_' + idMetier)
        ) ?? banniere
      }
    >
      <div className="up-layout">
        <div className="up-layout__content">{children}</div>
        {(idMetier || idMetier === 0) && (
          <Drawer links={drawerLinks()} />
        )}
      </div>
    </PageBannerLayout>
  );
};

export default UniversProduitsLayout;
