import image from './connexion_v3.png';
import './style.scss';

const AuthLayout = ({ children }) => {
  return (
    <>
      <div className="auth">
        <img src={image} alt="" />
        <div className="auth-body">{children}</div>
      </div>
    </>
  );
};

export default AuthLayout;
