import CLLayout from '../../../components/layout/CLLayout';
import useAuth from '../../../hooks/useAuth';
import Loader from '../../../components/ui/loader/Loader';
import React, { useCallback, useState } from 'react';
import {
  getDownloadsByCategory,
  telechargementClick,
} from '../../../services/apis';
import { RESSOURCE, SOUS_RUBRIQUE } from '../../../config/constants';
import { DOMAIN } from '../../../config/env';
import PDFIcon from '../../../components/ui/icons/PDFIcon';
import FournisseursProduitsLayout from '../../../components/layout/_fournisseurs_produits/FournisseursProduitsLayout';
import TitleRowTable from '../../../components/ui/tables/TitleRowTable';
import './style.scss';

const TelechargementCom = () => {
  const { magasin } = useAuth(); // magasins code
  const [categoryWithDownloadList, setCategoryWithDownloadList] =
    useState({});
  const [loading, setloading] = useState(true);

  const fetchSections = useCallback(async () => {
    try {
      const data = await getDownloadsByCategory(
        magasin.mag_code,
        SOUS_RUBRIQUE.TELECHARGEMENT_COM
      );
      setCategoryWithDownloadList(data);
    } catch (err) {
      console.log(err);
    }
  }, [magasin.mag_code]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchSections();
        setloading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [fetchSections]);

  const titleRow = { accessor: 'dc_libelle' };

  const dataRow = { accessor: 'downloads' };

  const columns = React.useMemo(
    () => [
      {
        accessor: 'dl_libelle',
      },
      {
        Cell: (row) => (
          <a
            href={`${
              row.dl_fichier
                ? DOMAIN.URL + RESSOURCE.DOWNLOAD + row.dl_fichier
                : row.dl_lien
            }`}
            target="_blank"
            rel="noreferrer"
            onClick={() => telechargementClick(row.dl_code)}
          >
            <PDFIcon width={20} height={20} />
          </a>
        ),
      },
    ],
    []
  );
  if (loading)
    return (
      <FournisseursProduitsLayout>
        <Loader />
      </FournisseursProduitsLayout>
    );
  if (!!!categoryWithDownloadList.length)
    return <FournisseursProduitsLayout> </FournisseursProduitsLayout>;

  return (
    <CLLayout>
      <div className="dl-com-wrap">
        <h3 className="dl-com-title">Téléchargements</h3>
        <section className="dl-com-table">
          <TitleRowTable
            columns={columns}
            data={categoryWithDownloadList}
            titleRow={titleRow}
            dataRow={dataRow}
            header={false}
          />
        </section>
      </div>
    </CLLayout>
  );
};

export default TelechargementCom;
