import moment from 'moment';

moment.updateLocale('fr', {
  months:
    'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split(
      '_'
    ),
  weekdays:
    'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
  meridiem: function (hours, minutes, isLower) {
    return hours < 12 ? 'PD' : 'MD';
  },
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD/MM/YYYY',
    LY: 'YYYY-MM-DD',
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // Used to determine first week of the year.
  },
});

// Date d'aujourd'hui au format yyyy-mm-dd pour l'utilisation dans les champs date
export const TODAY_DATE = moment().format('YYYY-MM-DD');

// Format a given date to a dd-mm-yyyy date
export const formatDate = (date) => {
  return moment(date).format('L');
};

// Retourne le premier jour de la semaine passé en param
export const dayOfWeek = (week) => {
  return moment()
    .year(moment().year())
    .week(week)
    .startOf('week')
    .format('L');
};

/**
 * Retourne le numéro de semaine en fonction de la date donnée
 */
export const toWeek = (date) => {
  return moment(date).week();
};

/**
 * Retourne la date donné au format DD/MM
 */
export const dayMonth = (date) => {
  return moment(date).format('DD/MM');
};

// Format a given number to a price format
export const formatPrix = (prix) => {
  if (prix > 0) {
    const formatter = new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR',
    });

    return formatter.format(prix);
  }
  return '-';
};
