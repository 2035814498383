import React from 'react';
import './style.scss';
import {PATH} from "../../../../config/constants";
import { Link } from 'react-router-dom';
import StarIcon from "../../icons/StarIcon";


const ContratsMajList = ({ semaine, contrats }) => {
    return (
        <div>
            <h4>Semaine {semaine}</h4>
            <ul>
                {contrats.map((contrat) => (
                    <li key={contrat.con_code}>
                        <p>{contrat.con_nom}
                            {contrat.mag_code != null && <span className="favoris"><StarIcon height="18px" width="18px" /></span>} {/* Condition pour afficher le composant StarIcon */}
                            : <Link
                        to={PATH.ARTICLES.replace(':con_code',contrat.con_code)}>{contrat.Nb}</Link>

                        </p>

                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ContratsMajList;
