import { DOMAIN } from '../config/env';
import api from './apiClient';

// ---------------------------------------- PROSPECTUS ----------------------------------------

/**
 * return all prospectus for the user
 * @param groupId user's group
 */
export async function getProspectusList(magasin) {
  return api.get(DOMAIN.URL_API + 'PrsProspectus/magasin/' + magasin);
}

// /**
//  * return all prospectus with command in progress for the user
//  * @param codeMagasin user's magcode
//  */
// export async function getProspectusCommandInProgress(codeMagasin) {
//   return axios.get(
//     DOMAIN.URL_API + 'PrsProspectus/MagCode/' + codeMagasin,
//     {
//       withCredentials: true,
//     }
//   );
// }

/**
 * return paginations for a given prospectus
 * @param prospectusId
 */
export async function getPaginations(prospectusId) {
  return api.get(
    DOMAIN.URL_API + 'PrsPagination/PspCProspectus/' + prospectusId
  );
}

/**
 * return all periodes
 */
export async function getPeriodes() {
  return api.get(DOMAIN.URL_API + 'PrsPeriode');
}

// ---------------------------------------- PRODUCTS ----------------------------------------

/**
 * return all products for a given prospectus
 */
export async function getProductsByProspectus(prospectusId) {
  return api.get(
    DOMAIN.URL_API + 'PrsBdd/PspCProspectus/' + prospectusId
  );
}

/**
 * Return all command info products set for a given magasin and a prospectus
 */
export async function getCommandInfoProducts(
  prospectusId,
  codeMagasin
) {
  return api.get(
    DOMAIN.URL_API +
      'PrsBdd/PspCProspectus/' +
      prospectusId +
      '/MagCode/' +
      codeMagasin
  );
}

/**
 * set quantity/price to command a given product
 */
export async function setCommandProduct(data) {
  return api.post(DOMAIN.URL_API + 'BiLigne', { ...data });
}

export async function getProductsCommandOnProspectus(
  prospectusId,
  codeMagasin
) {
  return api.get(
    DOMAIN.URL_API +
      'BiLigne/MagCode/' +
      codeMagasin +
      '/PspCProspectus/' +
      prospectusId
  );
}

/**
 * return all products for a given command
 * @param {*} commandId
 */
export async function getProductsCommand(commandId) {
  return api.get(DOMAIN.URL_API + 'BiLigne/BiCode/' + commandId);
}

//---------------------- PAM ----------------------------------
/**
 * Renvoie  la liste des pam pour un magasin donné
 *
 */
export async function getPam(mag_code) {
  let pam = {};

  await api.get(DOMAIN.URL_API + 'pam/' + mag_code).then((res) => {
    pam = res.data;
  });
  return pam;
}

// ---------------------------------------- COMMUNICATION LOCALE ----------------------------------------
export async function getZoneDistri(codeMagasin) {
  let zoneDistri = {};

  await api
    .get(
      DOMAIN.URL_API + 'ClDataDevis/MagZones/MagCode/' + codeMagasin
    )
    .then((res) => {
      zoneDistri = res.data;
    });
  return zoneDistri;
}

// Retourne le potentiel du magasin donné pour certains calculs de devis
export async function getPotentiel(codeMagasin) {
  let potentiel = {};

  await api
    .get(
      DOMAIN.URL_API + 'ClDataDevis/Potentiel/MagCode/' + codeMagasin
    )
    .then((res) => {
      potentiel = res.data;
    });
  return potentiel;
}
// Return total price for CL devis
export async function getChiffrementCL(cmz_code, cmdt_code) {
  let chiffrement = {};

  await api
    .get(
      DOMAIN.URL_API +
        'ClDataDevis/chiffrage/' +
        cmz_code +
        '/' +
        cmdt_code
    )
    .then((res) => {
      chiffrement = res.data;
    });
  return chiffrement;
}

// Call api to send devis by email
export async function CLsendDevisDistriSpe(data) {
  return api
    .post(DOMAIN.URL_API + 'ClDataDevis/distriSpe/mail', {
      data: { ...data },
    })
    .then((res) => {
      console.log(res.data);
    });
}

// Call api to send devis by email
export async function CLsendDevisDocSpe(data) {
  return api
    .post(DOMAIN.URL_API + 'ClDataDevis/docSpe/mail', {
      data: { ...data },
    })
    .then((res) => {
      console.log(res.data);
    });
}

// Call api to send devis by email
export async function CLsendDevisSMSBDD(data) {
  return api
    .post(DOMAIN.URL_API + 'ClDataDevis/smsbdd/mail', {
      data: { ...data },
    })
    .then((res) => {
      console.log(res.data);
    });
}

// Call api to send devis by email
export async function CLsendDevisSmsEmail(data) {
  return api
    .post(DOMAIN.URL_API + 'ClDataDevis/smsemail/mail', {
      data: { ...data },
    })
    .then((res) => {
      console.log(res.data);
    });
}

// Call api to send devis by email
export async function CLsendDevisMailing(data) {
  return api
    .post(DOMAIN.URL_API + 'ClDataDevis/mailing/mail', {
      data: { ...data },
    })
    .then((res) => {
      console.log(res.data);
    });
}

// ---------------------------------------- FOURNISSEURS ----------------------------------------
/**
 * Retourne la liste des fournisseurs pour les prospectus
 */

export async function getFournisseursList(magasin) {
  return api.get(
    DOMAIN.URL_API + 'PrsProspectusFournisseur/magasin/' + magasin
  );
}

/**
 * return all fournisseurs for a given prospectus
 */
export async function getFournisseursByProspectus(prospectusId) {
  return api.get(
    DOMAIN.URL_API +
      'PrsProspectusFournisseur/PspCProspectus/' +
      prospectusId
  );
}

/**
 * return a fournisseur command
 * @param codeMagasin user mag code
 * @param prospectusId prosp selected
 */
export async function getFournisseursCommandList(codeMagasin) {
  return api.get(DOMAIN.URL_API + 'BiTete/MagCode/' + codeMagasin);
}

// ---------------------------------------- COMMAND ----------------------------------------
export async function deleteCommand(codeCommand) {
  return api.delete(DOMAIN.URL_API + 'BiTete/BiCode/' + codeCommand);
}

/**
 * Send data to modify user command information
 * @param {*} codeCommand
 * @param {*} commentaire
 * @param {*} adrLivraison
 * @param {*} adrFacturation
 * @returns
 */
export async function setCommandInformations(
  codeCommand,
  commentaire = '',
  adrLivraison,
  adrFacturation,
  semLivraison
) {
  const data = {
    bi_code: codeCommand,
    data: {
      bi_commentaire: commentaire,
      bi_adr_liv: adrLivraison,
      bi_adr_fact: adrFacturation,
      bi_semaine_liv: semLivraison,
    },
  };
  return api.post(DOMAIN.URL_API + 'BiTete', { ...data });
}
