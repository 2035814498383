import React from 'react';
import CLdevisLayout from '../../../../components/layout/CLdevisLayout';
import CLfidSmsEmailForm from '../../../../components/ui/forms/communication/CLfidSmsEmailForm';
import { useParams } from 'react-router-dom';

const CLsmsmail = () => {
  const params = useParams(); // Param in url
  const option = parseInt(params.option); // option = 0 -> sms | = 1 -> email

  return (
    <CLdevisLayout
      title={`${option ? 'Emailing' : 'SMS'} fidélité`}
      alert="la demande doit être effectuée au minimum une semaine avant le début de l'opération"
    >
      <CLfidSmsEmailForm option={option} />
    </CLdevisLayout>
  );
};

export default CLsmsmail;
