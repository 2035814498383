import React, { useState } from 'react';
import logo from './Inedis - Logo quadri sans baseline - Pour Ciel.png';
import './style.scss';
import {
  chronoClick,
  getChronos,
  telechargementClick,
} from '../../../../services/apis';
import useAuth from '../../../../hooks/useAuth';
import { dayMonth } from '../../../../utils/dates';
import CaretUp from '../../icons/CaretUp';
import CaretDown from '../../icons/CaretDown';
import SearchBarInput from '../../input/SearchBarInput';
import { RESSOURCE } from '../../../../config/constants';
import { DOMAIN } from '../../../../config/env';
import StarIcon from '../../icons/StarIcon';

const ChronosList = () => {
  const { magasin } = useAuth(); // magasins code
  const [chronos, setChronos] = useState([]); // Liste des chronos
  const [filteredChronos, setFilteredChronos] = useState([]); // Liste des chronos filtrés
  const [filter, setFilter] = useState(''); // Filtre de recherche
  const idActus = 1;
  const idPromos = 2;
  const redBlink = 'U';
  const perPage = 13;
  const [currentPage, setCurrentPage] = useState(1);

  // Options pour les boutons
  const [options, setOptions] = useState([
    {
      id: idActus,
      libelle: 'Actus du moment',
      selected: true,
    },
    {
      id: idPromos,
      libelle: 'Promos du moment',
      selected: false,
    },
  ]);

  /** HandleChange filter */
  const handleChangeFilter = (event) => {
    const value = event.target.value;
    setFilter(value);
  };

  /**
   * Systeme de filtre */
  React.useEffect(() => {
    try {
      if (chronos.length) {
        let filterTemp = chronos;
        // Filtre recherche
        if (filter) {
          // Filtre sur le nom
          filterTemp = filterTemp.filter((chrono) => {
            return chrono.ch_titre
              .toString()
              .toLowerCase()
              .includes(filter);
          });
        }
        setFilteredChronos(filterTemp);
      }
    } catch (err) {
      console.log(err);
    }
  }, [chronos, filter, options]);

  React.useEffect(() => {
    const fetchData = async () => {
      const res = await getChronos(magasin.mag_code);
      setChronos(res);
    };

    fetchData();
  }, [magasin.mag_code]);

  const prevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const nextPage = () => {
    setCurrentPage((prevPage) =>
      Math.min(
        prevPage + 1,
        Math.ceil(filteredChronos.length / perPage)
      )
    );
  };

  /**
   * Gère la sélection d'une option dans le composant SwitchSquaredButton.
   * Met à jour l'état des options en fonction de l'option sélectionnée.
   *
   * @param {Object} selectedOption - L'option sélectionnée.
   */
  const handleOptionSelect = (selectedOption) => {
    const updatedOptions = options.map((option) => ({
      ...option,
      selected: option.libelle === selectedOption.libelle,
    }));
    setOptions(updatedOptions);
  };

  if (!chronos.length) return null;
  return (
    <div className="chronos-list-wrapper">
      <header className="chronos__head">
        <img className="inedis-logo" src={logo} alt="logo" />
        <div className="title">À la une</div>
      </header>
      <div className="chronos__content">
        <div className="chronos-search">
          <SearchBarInput
            placeholder="Rechercher ..."
            value={filter}
            onChange={(event) => handleChangeFilter(event)}
          />
        </div>
        <div className="chronos-list">
          <ul>
            {!!filteredChronos.length &&
              filteredChronos
                .slice(
                  (currentPage - 1) * perPage,
                  currentPage * perPage
                )
                .map((chrono) => (
                  <li>
                    <a
                      href={
                        DOMAIN.URL +
                        '/' +
                        RESSOURCE.CHRONO +
                        chrono.ch_fichier
                      }
                      target="_blank"
                      rel="noreferrer"
                      className={
                        redBlink === chrono.ch_rubrique ? 'blink' : ''
                      }
                      onClick={() => chronoClick(chrono.ch_code)}
                    >
                      <span>{dayMonth(chrono.ch_datedeb)}</span>
                      {chrono.ch_titre}
                      <span className="favoris">
                        {' '}
                        {!!chrono.favoris.length && (
                          <StarIcon height="15px" width="15px" />
                        )}
                      </span>
                    </a>
                  </li>
                ))}
          </ul>
          <div className="chronos-list__actions">
            <button
              onClick={() => prevPage()}
              disabled={currentPage === 1}
            >
              <CaretUp />
            </button>
            <button
              onClick={() => nextPage()}
              disabled={
                currentPage ===
                Math.ceil(filteredChronos.length / perPage)
              }
            >
              <CaretDown />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChronosList;
