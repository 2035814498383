import axios from 'axios';
import { PATH } from '../config/constants';
import { DOMAIN } from '../config/env';
import { useLocation } from 'react-router-dom';

const api = axios.create();

// Ajoute l'intercepteur
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;

// import axios from 'axios';
// import { DOMAIN } from '../config/env';

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Cas ou le CSRF token n'est plus valide
    if (error.response.status === 401) {
      localStorage.removeItem('token');
      
      // URL Différent de la page de login ou la racine de l'app pour eviter les boucles
      if (
        window.location.href !==
          DOMAIN.URL_APP + '#' + PATH.LOGIN_PAGE &&
        window.location.href !== DOMAIN.URL_APP
      ) {
        window.location.href = DOMAIN.URL_APP;
      }
    }
    return Promise.reject(error);
  }
);
