import React, { useCallback, useState } from 'react';
import './style.scss';
import FournisseursProduitsLayout from '../../../components/layout/_fournisseurs_produits/FournisseursProduitsLayout';
import Loader from '../../../components/ui/loader/Loader';
import useAuth from '../../../hooks/useAuth';
import { getDownloadsByCategory } from '../../../services/apis';
import { RESSOURCE, SOUS_RUBRIQUE } from '../../../config/constants';
import TitleRowTable from '../../../components/ui/tables/TitleRowTable';
import PDFIcon from './../../../components/ui/icons/PDFIcon';
import { DOMAIN } from '../../../config/env';

const Legislation = () => {
  const { magasin } = useAuth(); // Magasin code
  const [categoryWithDownloadList, setCategoryWithDownloadList] =
    useState({});
  const [loading, setLoading] = useState(true); // Indicate the call has not finished. Default to true

  /**
   * Récupère les sections pour le magasin
   */
  const fetchSections = useCallback(async () => {
    try {
      const data = await getDownloadsByCategory(
        magasin.mag_code,
        SOUS_RUBRIQUE.LEGISLATION
      );
      setCategoryWithDownloadList(data);
    } catch (err) {
      console.log(err);
    }
  }, [magasin.mag_code]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchSections();
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [fetchSections]);

  const titleRow = { accessor: 'dc_libelle' };

  const dataRow = { accessor: 'downloads' };

  const columns = React.useMemo(
    () => [
      {
        accessor: 'dl_libelle',
        Cell: (row) => {
          const className = [
            (row.dl_italique && 'italique', row.dl_gras && 'gras'),
          ].join(' ');
          return <span className={className}> {row.dl_libelle}</span>;
        },
      },
      {
        Cell: (row) => (
          <a
            href={`${DOMAIN.URL}/${RESSOURCE.DOWNLOAD}/${row.dl_fichier}`}
            target="_blank"
            rel="noreferrer"
          >
            <PDFIcon width={20} height={20} />
          </a>
        ),
      },
    ],
    []
  );

  if (loading)
    return (
      <FournisseursProduitsLayout>
        <Loader />
      </FournisseursProduitsLayout>
    );
  if (!!!categoryWithDownloadList.length)
    return <FournisseursProduitsLayout> </FournisseursProduitsLayout>;
  return (
    <FournisseursProduitsLayout>
      <div className="legislation">
        <h3 className="legislation-title">Legislations</h3>
        <section className="legislation-table">
          <TitleRowTable
            columns={columns}
            data={categoryWithDownloadList}
            titleRow={titleRow}
            dataRow={dataRow}
            header={false}
          />
        </section>
      </div>
    </FournisseursProduitsLayout>
  );
};

export default Legislation;
