import React, { useCallback, useMemo, useState } from 'react';
import './style.scss';
import useAuth from '../../../../../hooks/useAuth';
import { useSelector } from 'react-redux';
import {favoris, getContratsByMetier} from '../../../../../services/fournisseurs_produits_apis';
import NormalTable from '../../NormalTable';
import { Link } from 'react-router-dom';
import {
  PATH,
  RESSOURCE,
  EXPORTS,
} from '../../../../../config/constants';
import PDFIcon from '../../../icons/PDFIcon';
import JPGIcon from '../../../icons/JPGIcon';
import { DOMAIN } from '../../../../../config/env';
import XlsIcon from '../../../icons/XlsIcon';
import Button from '../../../button/Button';
import TrashIcon from "../../../icons/TrashIcon";
import StarIcon from "../../../icons/StarIcon";
import ChoixTarifModal from "../../../modal/ChoixTarifModal";

const ContratsTable = () => {
  const { magasin } = useAuth();
  const { idMetier } = useSelector((state) => state.metier);

  const [contratsList, setContratsList] = useState([]);
  const [loading, setLoading] = useState(true);
    const [modalVisible, setModalVisible] = useState(false);

    const handleModalOpen = () => {
        setModalVisible(true);
    };
  React.useEffect(
    () => console.log(contratsList, idMetier),
    [contratsList, idMetier]
  );

  const fetchContrats = useCallback(async () => {
    try {
      const data = await getContratsByMetier(
        magasin.mag_code,
        idMetier
      );
      setContratsList(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }, [idMetier, magasin.mag_code]);


    const handleFavoris = useCallback(async (con_code, mode) => {
        try {
            await favoris(magasin.mag_code, con_code, mode);
            setContratsList((prevList) =>
                prevList.map((contrat) =>
                    contrat.con_code === con_code ? { ...contrat, favoris: mode } : contrat
                )
            );
           // setRefreshTable(true); // Déclenche le rafraîchissement du tableau
        } catch (err) {
            console.log(err);
        }
    }, [magasin.mag_code]);


    React.useEffect(() => {
        if (idMetier >= 0) {
            fetchContrats();
        }
    }, [fetchContrats, idMetier,handleFavoris]);

    const columns = useMemo(
        () => [
            {
                Header: 'Fav',
                accessor: 'favoris',
                className: 'normal-table-center',
                Cell: ({row}) => {
                    // partie un peu tirée par les cheuveux, on fait 2 fois le test pour tester s'il y a un favoris, mais
                    // c'est parce qu'il y a un probleme dans le rechargement de gestcontrat, favoris, =1 au lieux de []
                    return (Object.keys(row.original.favoris).length > 0) ?
                        <span className="favoris" onClick={() => handleFavoris(row.original.con_code, 0)} title="Supprimer le contrat des favoris">
                            <StarIcon
                            height="18px"
                            width="18px"

                            />
                            </span>
                        : ((row.original.favoris!==1)?(
                            <span className="nofavoris" onClick={() => handleFavoris(row.original.con_code, 1)} title="Ajouter le contrat aux favoris">

                        <StarIcon
                            height="18px"
                            width="18px"

                        />
                            </span>):(
                            <span className="favoris" onClick={() => handleFavoris(row.original.con_code, 0)} title="Supprimer le contrat des favoris">
                            <StarIcon
                                height="18px"
                                width="18px"

                            />
                            </span>
                        ));
                },
            },
            {
        Header: 'Année',
        accessor: 'annee',
        className: 'normal-table-center',
      },
      {
        Header: 'Numéro',
        accessor: 'con_code',
        filter: 'includes',
        className: 'normal-table-center',
      },

      {
        Header: 'Fournisseur',
        accessor: 'con_nom',
        filter: 'includes',
        Cell: ({ row }) => {
          return (
            <Link
              to={PATH.CONTRAT_DETAILS.replace(
                ':con_code',
                row.original.con_code
              )}
            >
              {row.original.con_nom}
            </Link>
          );
        },
      },
      {
        Header: 'Chef Produits',
        accessor: 'che_nom',
        filter: 'includes',
      },
      {
        Header: 'Fiche',
        filter: 'includes',
        className: 'normal-table-icon',
        Cell: ({ row }) => {
          return (
            <a
              href={`${DOMAIN.URL}/${RESSOURCE.CONTRATS_FICHES}F_${row.original.con_code}.PDF`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <PDFIcon height={20} width={20} />
            </a>
          );
        },
      },
      {
        Header: 'Carte',
        accessor: 'con_carte',
        filter: 'includes',
        className: 'normal-table-icon',
        Cell: ({ value, row }) => {
          if (value === -1) {
            return (
              <a
                href={`${DOMAIN.URL}/${RESSOURCE.CONTRATS_CARTES}${row.original.con_code}.jpg`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <JPGIcon />
              </a>
            );
          } else {
            return null;
          }
        },
      },
      {
        Header: 'C.G.V.',
        accessor: 'con_cgv',
        filter: 'includes',
        className: 'normal-table-icon',
        Cell: ({ row }) => {
          if (row.original.con_cgv.length > 1) {
            return (
              <a
                href={`${DOMAIN.URL}/${RESSOURCE.CONTRATS_CGV}cgv_${row.original.annee}_${row.original.con_code}.pdf`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <PDFIcon height={20} width={20} />
              </a>
            );
          } else {
            return null;
          }
        },
      },
        {
            Header: 'Articles',
            accessor: 'con_nbart',
            className: 'normal-table-center',
        },
        {
            Header: ' Export ',
            className: 'normal-table-center',
            Cell: ({row}) => {
                if (row.original.con_nbart > 0) {
                    return (
                         <a
                            href={`${DOMAIN.URL_WEB}${EXPORTS.ARTICLES.replace(
                                ':idMetier',
                                idMetier
                            ).replace(':magasin', magasin.mag_code).replace(':con_code', row.original.con_code)}`}
                            target="_blank"
                            rel="noopener noreferrer"

                        ><div className="color-xls">
                             <XlsIcon height={20} width={20}/></div>
                        </a>
                    );
                } else {
                    return null;
                }
            },
        },
        {
            Header: ' LYNX ',
            className: 'normal-table-center',
            Cell: ({row}) => {
                if (row.original.con_nbart > 0) {
                    return (
                        <ChoixTarifModal con_code={row.original.con_code}/>

                    );
                } else {
                    return null;
                }
            },
        },
        {
            Header: ' - ',
            Cell: ({row}) => {
                if (row.original.con_nbart > 0) {
                    return (
                        <Link
                            to={PATH.ARTICLES.replace(
                                ':con_code',
                                row.original.con_code
                            )}
                        >
                            Tarifs
                        </Link>
                    );
                } else {
                    return null;
                }
            },
        },
        {
            Header: '-',
            Cell: ({row}) => {
                if  (Object.keys(row.original.articles_sup).length > 0)  {
                    return (
                        <Link
                            title="Articles supprimés"
                            to={PATH.ARTICLES_SUP.replace(
                                ':con_code',
                                row.original.con_code
                            )}
                        >
                            <TrashIcon/>
                        </Link>
                    );
                } else {
                    return null;
                }
            },
        },
    ],
        [contratsList,handleFavoris]
  );

  if (loading) return null;
  return (
    <div className="contrats-table">
      {/* <Button
        libelle="Exporter la liste"
        icon={<XlsIcon />}
        onClick={() =>
          window.open(
            `${DOMAIN.URL_WEB}${EXPORTS.CONTRATS.replace(
              ':idMetier',
              idMetier
            ).replace(':magasin', magasin.mag_code)}`,
            '_blank'
          )
        }
      /> */}
      <NormalTable
        columns={columns}
        data={contratsList}
        actions={
          <Button
            libelle="Exporter la liste"
            icon={<XlsIcon />}
            onClick={() =>
              window.open(
                `${DOMAIN.URL_WEB}${EXPORTS.CONTRATS.replace(
                  ':idMetier',
                  idMetier
                ).replace(':magasin', magasin.mag_code)}`,
                '_blank'
              )
            }
          />
        }
      />
    </div>
  );
};

export default ContratsTable;
