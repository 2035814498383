import MdgLayout from '../../../components/layout/_fournisseurs_produits/MdgLayout';
import { useCallback, useState } from 'react';
import Loader from '../../../components/ui/loader/Loader';
import { Outlet } from 'react-router-dom';
import { getTelechargementsMDG } from '../../../services/apis';
import useAuth from '../../../hooks/useAuth';
import React from 'react';
import './style.scss';

const Mdg = () => {
  const { magasin } = useAuth();
  const [loading, setLoading] = useState({});
  const [mdgList, setMdgList] = useState({});

  const fetchMdg = useCallback(async () => {
    try {
      const data = await getTelechargementsMDG(magasin.mag_code);
      setMdgList(data);
    } catch (err) {
      console.log(err);
    }
  }, [magasin.mag_code]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchMdg();
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [fetchMdg]);

  const data = {
    mdgList,
  };

  if (loading)
    return (
      <MdgLayout>
        <Loader />
      </MdgLayout>
    );
  if (!!!mdgList.length) return <MdgLayout>Aucune donnée</MdgLayout>;
  return (
    <MdgLayout>
      <div className="mdg-conteneur">
        <Outlet context={data} />
      </div>
    </MdgLayout>
  );
};
export default Mdg;
