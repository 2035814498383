import React, { useState } from 'react';
import Button from '../../button/Button';
import Modal from '../Modal';
import './style.scss';

const ValidationMailModal = ({
  children,
  onClick,
  bodyicon,
  ...props
}) => {
  const [loading, setLoading] = useState(false); // Chargement pendant l'éxecution de l'evenement on click

  // Execute l'évenement on click
  const handleClick = () => {
    const doEvent = async () => {
      await onClick();
      setLoading(false);
    };
    setLoading(true);
    doEvent();
  };

  return (
    <Modal {...props}>
      <div className="validation-mail-modal-wrap">
        <header className="validation-mail-modal-bg">
          <div className="bg-image"></div>
        </header>
        {bodyicon}
        <div className="validation-mail-modal">
          <h3 className="validation-mail-modal__title">
            {props.title}
          </h3>
          <div className="validation-mail-modal__content">
            {children}
          </div>

          <div className="validation-mail-modal__action">
            <Button
              libelle="Confirmer"
              color={props.color}
              onClick={() => handleClick()}
              loading={loading}
            />
            <Button
              libelle="Annuler"
              color="color-neutral"
              onClick={props.hide}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ValidationMailModal;
