import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './screens/general/home';
import Login from './screens/authentification/Login';

import './App.scss';
import BDC from './screens/communication/BDC';
import CL from './screens/communication/CL';
import BDCselectionProspectus from './screens/communication/BDC/BDCselectionProspectus';
import BDCselectionProduits from './screens/communication/BDC/BDCselectionProduits';
import BDCselectionCommandeAll from './screens/communication/BDC/BDCselectionCommandeAll';
import BDCselectionCommandeProspectus from './screens/communication/BDC/BDCselectionCommandeProspectus';
import BDCcommande from './screens/communication/BDC/BDCcommande';
import CLdistriSpe from './screens/communication/CL/CLdistriSpe';
import AppLayout from './components/layout/AppLayout';
import CLselectionOption from './screens/communication/CL/CLselectionOption';
import CLdocSpe from './screens/communication/CL/CLdocSpe';
import CLsmsbdd from './screens/communication/CL/CLsmsbdd';
import CLmailing from './screens/communication/CL/CLmailing';
import CLsmsmail from './screens/communication/CL/CLsmsmail';
import { PATH } from './config/constants';
import AuthRoute from './components/guards/AuthRoute';
import AE from './screens/fournisseurs_produits/AE';
import AEselectionSection from './screens/fournisseurs_produits/AE/AEselectionSection';
import AEsection from './screens/fournisseurs_produits/AE/AEsection';
import Legislation from './screens/fournisseurs_produits/Legislation';
import Formations from './screens/fournisseurs_produits/Formations';
import DocsEvent from './screens/fournisseurs_produits/DocsEvent';
import Solutions from './screens/fournisseurs_produits/Solutions';
import ContactsPage from './screens/general/contact';
import TelechargementCom from './screens/communication/Telechargement';
import Pam from './screens/communication/PAM';
import AideAchatVte from './screens/fournisseurs_produits/AideAchatVte';
import Mdg from './screens/fournisseurs_produits/MDG';
import MDGselection from './screens/fournisseurs_produits/MDG/MDGselection';
import MDGTelechargements from './screens/fournisseurs_produits/MDG/MDGTelechargements';
import SelectMagasin from './screens/general/SelectMagasin';
import Planos from './screens/fournisseurs_produits/UniversProduits/Produits/Planos';
import PlanoDetail from './screens/fournisseurs_produits/UniversProduits/Produits/PlanoDetail';
import UniversProduits from './screens/fournisseurs_produits/UniversProduits';
import UPselectionInfo from './screens/fournisseurs_produits/UniversProduits/UPselectionInfo';
import UPselectionMetier from './screens/fournisseurs_produits/UniversProduits/UPselectionMetier';
import Articles from './screens/fournisseurs_produits/UniversProduits/Produits/Articles';
import ContratDetail from './screens/fournisseurs_produits/UniversProduits/Produits/ContratDetail';
import PrecoFournisseur from './screens/fournisseurs_produits/UniversProduits/Produits/PrecoFournisseur';
import MisesAJour from './screens/fournisseurs_produits/UniversProduits/MisesAJour';
import ArticlesSup from './screens/fournisseurs_produits/UniversProduits/Produits/ArticlesSup';
import Newsletter from './screens/general/newsletter';
import ForgotPassword from './screens/authentification/ForgotPassword';
import ResetPassword from './screens/authentification/ResetPassword';
import Authentification from './screens/authentification';
import Profile from './screens/Profil';
import ChangePassword from './screens/authentification/ChangePassword';
import { STATUS } from './config/env';
import Maintenance from './screens/general/Maintenance';
import InscriptionSalon from './screens/general/salon/InscriptionSalon';

const App = () => {
  if (STATUS.MAINTENANCE) return <Maintenance />;

  return (
    <Routes>
      <Route element={<Authentification />}>
        <Route path={PATH.LOGIN_PAGE} element={<Login />} />
        <Route
          path={PATH.RESET_PASSWORD}
          element={<ResetPassword />}
        />
        <Route
          path={PATH.FORGOT_PASSWORD}
          element={<ForgotPassword />}
        />
        <Route
          path={PATH.CHANGE_PASSWORD}
          element={<AuthRoute outlet={<ChangePassword />} />}
        />
      </Route>
      {/* Routes pour les bons de commande communication */}
      <Route
        path={PATH.SELECT_MAGASIN}
        element={<AuthRoute outlet={<SelectMagasin />} />}
      />
      <Route element={<AuthRoute outlet={<AppLayout />} />}>
        <Route index element={<Home />} />
        <Route path="*" element={<Navigate to={PATH.HOME_PAGE} />} />
        <Route path={PATH.PROFILE} element={<Profile />} />
        <Route path={PATH.NEWSLETTER} element=<Newsletter /> />
        <Route path={PATH.CONTACT_PAGE} element={<ContactsPage />} />
        <Route path={PATH.PAM} element={<Pam />} />
        <Route
          path={PATH.TELECHARGEMENT_COM}
          element={<TelechargementCom />}
        />
        <Route path={PATH.PAM} element={<Pam />} />
        <Route
          path={PATH.TELECHARGEMENT_COM}
          element={<TelechargementCom />}
        />
        <Route element={<BDC />}>
          <Route
            path={PATH.BDC_PROSPECTUS_PAGE}
            element={<BDCselectionProspectus />}
          />
          <Route
            path={PATH.BDC_PRODUITS_PAGE}
            element={<BDCselectionProduits />}
          />
          <Route
            path={PATH.BDC_COMMANDES_ALL_PAGE}
            element={<BDCselectionCommandeAll />}
          />
          <Route
            path={PATH.BDC_COMMANDES_PROSPECTUS_PAGE}
            element={<BDCselectionCommandeProspectus />}
          />
          <Route
            path={PATH.BDC_COMMANDE_PAGE}
            element={<BDCcommande />}
          />
        </Route>
        {/* Routes pour les communications locales  */}
        <Route element={<CL />}>
          <Route
            path={PATH.CL_OPTIONS_PAGE}
            element={<CLselectionOption />}
          />
          <Route
            path={PATH.CL_OPTION_1_PAGE}
            element={<CLdistriSpe />}
          />
          <Route
            path={PATH.CL_OPTION_2_PAGE}
            element={<CLdocSpe />}
          />
          <Route
            path={PATH.CL_OPTION_3_PAGE}
            element={<CLsmsbdd />}
          />
          <Route
            path={PATH.CL_OPTION_4_PAGE}
            element={<CLmailing />}
          />
          <Route
            path={PATH.CL_OPTION_56_PAGE}
            element={<CLsmsmail />}
          />
        </Route>

        {/* ***************** FOURNISSEURS & PRODUITS ***************** */}
        <Route path={PATH.ARTICLES} element={<Articles />} />
        <Route element={<UniversProduits />}>
          <Route
            path={PATH.PRECO_FOURNISSEUR}
            element={<PrecoFournisseur />}
          />
          <Route path={PATH.PLANOS} element={<Planos />} />
          <Route
            path={PATH.PLANOS_DETAILS}
            element={<PlanoDetail />}
          />
          <Route path={PATH.ARTICLES_SUP} element={<ArticlesSup />} />
          <Route
            path={PATH.CONTRAT_DETAILS}
            element={<ContratDetail />}
          />
          <Route
            path={PATH.UP_SELECTION_METIER}
            element={<UPselectionMetier />}
          />

          <Route
            path={PATH.UP_SELECTION_INFO}
            element={<UPselectionInfo />}
          />
        </Route>
        <Route element={<Mdg />}>
          <Route path={PATH.MDG_PAGE} element={<MDGselection />} />
          <Route
            path={PATH.MDG_SRUB_PAGE}
            element={<MDGTelechargements />}
          />
        </Route>
        <Route path={PATH.MISES_A_JOUR} element={<MisesAJour />} />
        <Route
          path={PATH.AIDE_ACHAT_VTE}
          element={<AideAchatVte />}
        />

        <Route path={PATH.SOLUTION} element={<Solutions />} />
        <Route
          path={PATH.AIDE_ACHAT_VTE}
          element={<AideAchatVte />}
        />
        <Route path={PATH.SOLUTION} element={<Solutions />} />
        <Route element={<AE />}>
          <Route
            path={PATH.AE_SECTIONS}
            element={<AEselectionSection />}
          />
          <Route path={PATH.AE_SECTION} element={<AEsection />} />
        </Route>
        <Route path={PATH.LEGISLATION} element={<Legislation />} />
        <Route path={PATH.FORMATION} element={<Formations />} />
        <Route path={PATH.DOCS_EVENT} element={<DocsEvent />} />
        {/* Salon Inscription */}
        <Route
          path={PATH.SALON_INSCRIPTION}
          element={<InscriptionSalon />}
        />
      </Route>
    </Routes>
  );
};

export default App;
