import React from 'react';
import './style.scss';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { formatPrix } from '../../../../../../utils/dates';

const BDCpriceTable = ({ product }) => {
  return (
    <div className="condition-table-wrap">
      <table className="condition-table">
        <thead>
          <tr className="condition-table__head">
            <th className="condition-table__head-row">
              PA&nbsp;H.T.
            </th>
            <th className="condition-table__head-row">
              Conditionnement
            </th>
            <th className="condition-table__head-row">
              PA&nbsp;H.T.&nbsp;Promo&nbsp;
            </th>
            <th
              className="condition-table__head-row"
              title="Correspond au PV TTC/ PA HT promo"
            >
              <div className="headrow-text">
                Coefficient
                <HelpOutlineIcon className="info-bulle" />
              </div>
            </th>
            <th className="condition-table__head-row">TVA</th>
            <th className="condition-table__head-row">
              Ecotaxes&nbsp;TTC
            </th>
            <th
              className="condition-table__head-row"
              title="(dont ecotaxes) Les prix TTC du prospectus sont
              susceptibles d'évoluer"
            >
              <div className="headrow-text">
                PV&nbsp;indicatif
                <HelpOutlineIcon className="info-bulle" />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="condition-table__body">
            <td className="condition-table__body-row" rowSpan="3">
              {formatPrix(product.pbd_ht)}
            </td>
            <td className="condition-table__body-row">
              {product.pbd_ht_promo_unit}
            </td>
            <td className="condition-table__body-row">
              {product.pbd_ht_promo > 0 ? (
                <>{formatPrix(product.pbd_ht_promo)} </>
              ) : (
                <></>
              )}
            </td>
            <td className="condition-table__body-row">
              {product.pbd_coeff > 0 ? (
                <>{product.pbd_coeff.toFixed(2)} </>
              ) : (
                <></>
              )}
            </td>
            <td className="condition-table__body-row" rowSpan="3">
              {product.tva_code}&nbsp;%
            </td>
            <td className="condition-table__body-row" rowSpan="3">
              {formatPrix(product.pbd_deee_ttc)}
            </td>
            <td className="condition-table__body-row" rowSpan="3">
              {formatPrix(product.pbd_pv_ttc)}
            </td>
          </tr>
          {parseInt(product.pbd_ht_1promo_unit) >= 1 && (
            <>
              <tr className="condition-table__body">
                <td className="condition-table__body-row">
                  {product.pbd_ht_1promo_unit}
                </td>
                <td className="condition-table__body-row">
                  {product.pbd_ht_1promo > 0 ? (
                    <>{formatPrix(product.pbd_ht_1promo)}</>
                  ) : (
                    <></>
                  )}
                </td>
                <td className="condition-table__body-row">
                  {product.pbd_coeff1 > 0 ? (
                    <>{product.pbd_coeff1.toFixed(2)} </>
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            </>
          )}

          {parseInt(product.pbd_ht_2promo_unit) >= 1 && (
            <>
              <tr className="condition-table__body">
                <td className="condition-table__body-row">
                  {product.pbd_ht_2promo_unit}
                </td>
                <td className="condition-table__body-row">
                  {product.pbd_ht_2promo > 0 ? (
                    <>{formatPrix(product.pbd_ht_2promo)} </>
                  ) : (
                    <></>
                  )}
                </td>
                <td className="condition-table__body-row">
                  {product.pbd_coeff2 > 0 ? (
                    <>{product.pbd_coeff2.toFixed(2)} </>
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default BDCpriceTable;
