import axios from 'axios';
import { PATH } from '../config/constants';

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Rediriger vers la page de connexion
      window.location.href = '/login'; // Utilisation de window.location pour la redirection
    }
    return Promise.reject(error);
  }
);
