import React, { useState } from 'react';
import { EXPORTS } from '../../../../config/constants';
import { DOMAIN } from '../../../../config/env';
import useAuth from '../../../../hooks/useAuth';
import { useSelector } from 'react-redux';
import useModal from '../../../../hooks/useModal';
import './style.scss';
import Button from '../../button/Button';
import Modal from '../Modal';

const ChoixTarifModal = ({ con_code }) => {
  const [selectedOption, setSelectedOption] = useState('option1');
  const { isShowing, toggle, openModal } = useModal();

  const { magasin } = useAuth();
  const { idMetier } = useSelector((state) => state.metier);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleButtonClick = () => {
    const tarif = selectedOption;

    const url = `${DOMAIN.URL_WEB}${EXPORTS.ARTICLES_LYNX.replace(
      ':idMetier',
      idMetier
    )
      .replace(':magasin', magasin.mag_code)
      .replace(':con_code', con_code)
      .replace(':tarif', tarif)}`;

    // Faire ce que vous voulez avec l'URL générée (par exemple, redirection vers cette URL)
    window.open(url);
    toggle();
  };

  return (
    <div className="choix-tarif-modal">
      <button onClick={() => openModal()}>Choix du tarif</button>
      <Modal isShowing={isShowing} hide={toggle}>
        <div className="tarif-modal">
          <h3>Choix du tarif</h3>
          <div className="select-tarif">
            <label
              className={`select-tarif__field ${
                selectedOption === 'option1'
                  ? 'select-tarif__field--selected'
                  : ''
              }`}
            >
              <input
                type="radio"
                value="option1"
                checked={selectedOption === 'option1'}
                onChange={handleOptionChange}
              />
              Tarif en cours
            </label>

            <label
              className={`select-tarif__field ${
                selectedOption === 'option2'
                  ? 'select-tarif__field--selected'
                  : ''
              }`}
            >
              <input
                type="radio"
                value="option2"
                checked={selectedOption === 'option2'}
                onChange={handleOptionChange}
              />
              Tarif à venir
            </label>
          </div>
          <footer className="select-tarif__action">
            <Button
              onClick={() => handleButtonClick()}
              libelle="Télécharger"
            />
            <Button
              libelle="Annuler"
              color={'color-secondary'}
              onClick={toggle}
            />
          </footer>
        </div>
      </Modal>
    </div>
  );


  //   return (
  //     <div className="choix-tarif-modal">
  //       <select value={selectedOption} onChange={handleOptionChange}>
  //         <option value="1">Choix du tarif</option>
  //         <option value="1">Tarif en cours</option>
  //         <option value="2">Tarif à venir</option>
  //       </select>
  //       <button onClick={handleButtonClick}>
  //         <XlsIcon height={20} width={20} />
  //       </button>
  //     </div>
  //   );
};

export default ChoixTarifModal;
