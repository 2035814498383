import './style.scss';


const ExternalLink = ({
                      libelle,
                      sublibelle,
                      className,
                      href,
                        target="_blank",
                      icon,
                      rest,
                  }) => {
    if (!libelle) return null;
    return (
        <a className={className + ' filled-link'} href={href} target={target} {...rest}>
            {icon}
            <span className="libelle">
        {libelle}
                <span className="sublibelle">{sublibelle}</span>
      </span>
        </a>
    );
};

export default ExternalLink;