import React, { useState } from 'react';
import PasswordInput from '../../../components/ui/input/PasswordInput';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { updatePassword } from '../../../services/auth_apis';
import { REDIRECT_LOGIN } from '../../../config/constants';
import Button from '../../../components/ui/button/Button';
import useAuth from '../../../hooks/useAuth';

const ChangePassword = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { selectionMagasin } = useAuth();
  // Données du formulaire
  const [data, setData] = useState({
    username: params.username,
    password: '',
    password_confirmation: '',
  });
  const [errors, setErrors] = useState({}); // Erreurs pour le formulaire
  const [loading, setLoading] = useState(false); // Indicateur de chargement pour la connection

  // Validation des données du formulaire
  const validationSchema = Yup.object().shape({
    username: Yup.string().required('* Ce champs est obligatoire'),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,16})/,
        'Minimum 10 caractères, 1 majuscule, 1 minuscule et 1 chiffre'
      )
      .required('* Ce champs est obligatoire'),
    password_confirmation: Yup.string()
      .oneOf(
        [Yup.ref('password'), null],
        'Le mot de passe ne correspond pas'
      )
      .required('* Ce champs est obligatoire'),
  });

  /** Modification des valeurs des champs */
  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  /**
   * Validation du formulaire
   */
  const handleSubmit = () => {
    // Validation des champs de saisie
    const validate = async () => {
      const errorBag = {};
      await validationSchema
        .validate(data, { abortEarly: false })
        .catch((err) => {
          err?.inner.forEach((res) => {
            errorBag[res.path] = res.message;
          });
        });
      setErrors(errorBag);
      if (Object.keys(errorBag).length) {
        throw new Error('Champs invalides');
      }
    };
    try {
      validate().then(() => {
        setLoading(true);
        updatePassword(data)
          .then(() => {
            selectionMagasin();
          })
          .catch(() => {
            setLoading(false);
          });
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <form className="form">
        <h3>
          Changement <br /> mot de passe
        </h3>
        <div className="form-inputs">
          <PasswordInput
            type="password"
            name="password"
            label="Nouveau mot de passe"
            value={data.password}
            onChange={(event) =>
              handleChange(event.target.name, event.target.value)
            }
            error={errors.password}
          />
          <PasswordInput
            type="password"
            name="password_confirmation"
            label="Confirmation mot de passe"
            value={data.password_confirmation}
            onChange={(event) =>
              handleChange(event.target.name, event.target.value)
            }
            error={errors.password_confirmation}
          />
        </div>

        <div className="form-submit">
          <Button
            libelle="Valider"
            loading={loading}
            color="primary-btn"
            onClick={() => handleSubmit()}
          />
          {/* <Link to={PATH.LOGIN_PAGE}> &#8592; connexion </Link> */}
        </div>
      </form>
    </>
  );
};

export default ChangePassword;
