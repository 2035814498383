import React from 'react';
import ReturnBtn from '../../../../components/ui/button/ReturnBtn';
import {
  PATH,
  RESSOURCE,
  EXPORTS,
  METIER,
} from '../../../../config/constants';
import { DOMAIN } from '../../../../config/env';

import { useSelector } from 'react-redux';
import './style.scss';
import TextCard from '../../../../components/ui/cards/TextCard';
import UPbookList from '../../../../components/ui/lists/_fournisseurs_produits/UPbookList';
import ContratsTable from '../../../../components/ui/tables/_fournisseurs_produits/ContratsTable';

const UPselectionInfo = () => {
  const { idMetier } = useSelector((state) => state.metier);

  return (
    <div className="selection-info-screen">
      <ReturnBtn link={PATH.UP_SELECTION_METIER} />
      <div className="selection-info">
        <div className="select-info-cards">
          <TextCard
            libelle={'Base article'}
            to={PATH.ARTICLES.replace(':con_code',0)  }
          />

          <TextCard
            libelle={'Préconisation fournisseurs'}
            to={PATH.PRECO_FOURNISSEUR.replace(':idMetier', idMetier)}
          />
          <TextCard
            libelle={'Plano gamme'}
            to={PATH.PLANO_BASE_URL + '/' + idMetier}
          />
        </div>
        {METIER.METIER_ID_CHRONO.includes(idMetier) && <UPbookList />}
        <ContratsTable />
      </div>
    </div>
  );
};

export default UPselectionInfo;
