import React from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import ReturnBtn from '../../../../components/ui/button/ReturnBtn';
import AEprestataireCard from '../../../../components/ui/cards/_fournisseurs_produits/AEprestataireCard';
import './style.scss';

const AEsection = () => {
  const { sec_id } = useParams();
  const { sectionsList } = useOutletContext();

  const section = React.useMemo(() => {
    return sectionsList?.find((sec) => {
      return sec.sec_id === parseInt(sec_id);
    });
  }, [sectionsList, sec_id]);

  return (
    <div className="ae-section">
      <ReturnBtn />
      <h3 className="ae-section-title">{section.sec_nom}</h3>
      <div className="ae-section-prestataires">
        {section.prestataires.map((prestataire) => (
          <div
            className="ae-section-prestataires-card-wrapper"
            key={`presta_${prestataire.pre_id}`}
          >
            <AEprestataireCard prestation={prestataire} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AEsection;
