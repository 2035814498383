import React from 'react';
import './style.scss';
import { useState } from 'react';
import Input from '../../../input/Input';
import Select from '../../../input/Select';
import Textarea from '../../../input/Textarea';
import useAuth from '../../../../../hooks/useAuth';
import { calculSMSEmail } from '../../../../../utils/CL_devis_calculs';
import { useOutletContext } from 'react-router-dom';
import { CLsendDevisSmsEmail } from '../../../../../services/communication_apis';
import { formatDate, TODAY_DATE } from '../../../../../utils/dates';
import validation from './validation';
import CLdevisModal from '../../../modal/communication/CLdevisModal';

const CLfidSmsEmailForm = ({ option }) => {
  const { potentiel } = useOutletContext(); // Pour le calcul du montant du devis
  const [isValidForm, setIsValidForm] = useState(false); // Indicateur sur la validité du formulaire
  const [errors, setErrors] = useState({}); // Erreurs du formulaire à afficher

  const { user } = useAuth();
  const [tarification, setTarification] = useState({
    frais_fixes: '',
    cout_envoie: '',
    minimum_commande: '',
    service: '',
    montant: '',
    quantite: '',
    maj: '',
  });
  const whiteData = {
    email: user.email,
    date_valid_deb: TODAY_DATE,
    date_valid_fin: TODAY_DATE,
    landing: 1,
    com: '',
    offre: '',
    ciblage: 0,
  };

  const [data, setData] = useState(whiteData);

  // Send mail devis on validation
  const handleSendDevis = async () => {
    const el1 = document.getElementById('ciblage');

    // Données à envoyer pour le mail
    let d = {
      ...data,
      date_valid_deb: formatDate(data.date_valid_deb),
      date_valid_fin: formatDate(data.date_valid_fin),
      tarification: tarification,
      ciblage: el1.options[el1.selectedIndex].text,
    };
    // Pas de landing dans le cas d'un email
    if (option) {
      delete d.landing;
    } else {
      d = {
        ...d,
        landing: !!parseInt(data.landing) ? 'oui' : 'non',
      };
    }
    await CLsendDevisSmsEmail(d);
    setData(whiteData);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validation check for inputs
    const validate = async () => {
      const errorBag = await validation(data);
      setErrors(errorBag);
      const hasErrors = Object.keys(errorBag).length;
      if (hasErrors) {
        throw new Error('Champs invalides');
      }
      setIsValidForm(!hasErrors);
      // Chiffrage uniquement si première options selectionnée
      let tarifs = { ...tarification, montant: null };
      parseInt(data.ciblage) === 0 &&
        (option
          ? (tarifs = {
              ...tarification,
              montant: calculSMSEmail(tarification),
            })
          : (tarifs = {
              ...tarification,
              montant: calculSMSEmail(tarification),
            }));
      setTarification(tarifs);
    };
    try {
      setIsValidForm(false);
      validate();
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    let tarifs = {};
    switch (option) {
      // SMS
      case 0:
        tarifs = {
          service: 'SMS',
          frais_fixes: !!parseInt(data.landing) ? 190 : 30,
          cout_envoie: 0.06,
          minimum_commande: 250,
          quantite: potentiel.cxmd_qtesms,
          maj: potentiel.cxmd_datemajsms,
        };
        break;
      // EMAIL
      case 1:
        tarifs = {
          service: 'email',
          frais_fixes: 190,
          cout_envoie: 0.007,
          minimum_commande: 1000,
          quantite: potentiel.cxmd_qtemail,
          maj: potentiel.cxmd_datemajmail,
        };
        break;
      default:
    }
    setTarification(tarifs);
  }, [data.landing, option, potentiel, setTarification]);

  return (
    <form
      onSubmit={(data) => handleSubmit(data)}
      className="cl-smsmail"
    >
      <div className="date-validite">
        <Input
          type="date"
          min={TODAY_DATE}
          label="Début date de validité"
          name="date_valid_deb"
          value={data.date_valid_deb}
          onChange={(event) => handleChange(event)}
          error={errors.date_valid_deb}
        />
        <Input
          type="date"
          min={data.date_valid_deb}
          label="Fin date de validité"
          name="date_valid_fin"
          value={data.date_valid_fin}
          onChange={(event) => handleChange(event)}
          error={errors.date_valid_fin}
        />
      </div>

      <Textarea
        label="Offre"
        rows="2"
        maxLength="255"
        placeholder="Indiquez le texte souhaité."
        name="offre"
        value={data.offre}
        onChange={(event) => handleChange(event)}
        error={errors.offre}
      />
      <Select
        label="Ciblage"
        name="ciblage"
        id="ciblage"
        value={data.ciblage}
        onChange={(event) => handleChange(event)}
        error={errors.ciblage}
      >
        <option value={0}>Tous clients </option>
        <option value={1}>
          Clients actifs 12 derniers (sur devis)
        </option>
        <option value={2}>Autres ciblages (sur devis)</option>
      </Select>
      {!option && (
        <Select
          label="Landing page"
          name="landing"
          value={data.landing}
          onChange={(event) => handleChange(event)}
          error={errors.landing}
        >
          <option value={1}>Oui</option>
          <option value={0}>Non</option>
        </Select>
      )}

      <div>
        <h4> Rappel des tarifs HT :</h4>
        <ul>
          <li>Frais fixes : {tarification.frais_fixes}&nbsp;€</li>
          <li>
            Envoi : {tarification.cout_envoie} € /{' '}
            {tarification.service} (minimum de commande &ge;
            {tarification.minimum_commande})
          </li>
        </ul>
      </div>
      <Input
        type="email"
        label="Email"
        name="email"
        value={data.email}
        onChange={(event) => handleChange(event)}
        error={errors.email}
      />
      <Textarea
        label="Commentaire"
        rows="5"
        maxLength="255"
        placeholder=" "
        name="com"
        value={data.com}
        onChange={(event) => handleChange(event)}
        error={errors.com}
      />
      <div className="action-btn">
        <CLdevisModal
          isValidForm={isValidForm}
          onValidation={() => handleSendDevis()}
          tarification={tarification}
          email={data.email}
        />
      </div>
    </form>
  );
};

export default CLfidSmsEmailForm;
