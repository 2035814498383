import { DOMAIN } from '../config/env';
import api from './apiClient';

/**
 * Recupere les telechargements des sous rubriques MDG en fonction du magasin
 */
export async function getTelechargementsMDG(mag_code) {
  let downloads = {};

  await api
    .get(DOMAIN.URL_API + 'telechargementsMDG/' + mag_code)
    .then((res) => {
      downloads = res.data;
    });
  return downloads;
}

/**

  * Recupere les telechargements par catégories en fonction du magasin et de la sous rubrique souhaitée

  */

export async function getDownloadsByCategory(mag_code, dsr_code) {
  let downloads = {};
  await api
    .get(
      DOMAIN.URL_API + 'telechargements/' + mag_code + '/' + dsr_code
    )
    .then((res) => {
      downloads = res.data;
    });
  return downloads;
}

/**
 * Récupère les chronos en fonction du magasin.
 */
export async function getChronos(mag_code) {
  const res = await api.get(
    DOMAIN.URL_API + 'chrono/magasin/' + mag_code
  );
  return res.data;
}

/* incremente le click sur le chrono passé en paramètre */
export async function chronoClick(ch_code) {
  await api.get(DOMAIN.URL_API + 'chronoClick/' + ch_code);
  return true;
}

/**
 * Récupère les photos de contrats en fonction du magasin.
 */
export async function getContratsPhotos(mag_code) {
  const res = await api.get(
    DOMAIN.URL_API + 'contrats/photo/' + mag_code
  );
  return res.data;
}

/* incremente le click sur le telechargement passé en paramètre*/
export async function telechargementClick(dl_code) {
  await api.get(DOMAIN.URL_API + 'telechargementClick/' + dl_code);
  return true;
}

/**
 * Renvoie  la liste des contacts
 *
 */
export async function getContacts() {
  let contacts = {};

  await api.get(DOMAIN.URL_API + 'contact').then((res) => {
    contacts = res.data;
  });
  return contacts;
}

export async function getNewsletters(mag_code) {
  const url = DOMAIN.URL_API + 'newsletters/magasin/' + mag_code;
  const response = await api.get(url);

  return response.data;
}

/**
 * Compte le nombre de connexion a un magasin donné
 *  
 */
export async function counterMagasinConnexion(mag_code) {
  const url = DOMAIN.URL_API + 'magasin/counter/' + mag_code;
  const response = await api.get(url);

  return response.data;
}
