import { Outlet } from 'react-router-dom';
import AuthLayout from '../../components/layout/AuthentificationLayout';

const Authentification = () => {
  return (
    <>
      <AuthLayout>
        <Outlet />
      </AuthLayout>
    </>
  );
};

export default Authentification;
