import React, { useMemo } from 'react';
import './style.scss';
import { useTable } from 'react-table';
import Cell from './Cell';

const MergedRowTable = ({ data, columns }) => {
  /**
   * Fusionne les cellules de la colonne spécifiée pour des lignes consécutives avec la même valeur.
   *
   * @param {Array} rows - Les données des lignes à fusionner.
   * @param {string} columnIndex - L'index de la colonne à fusionner.
   * @returns {Array} - Les lignes fusionnées avec la propriété rowspan pour la colonne spécifiée.
   */
  const mergeCells = (rows, columnIndex) => {
    let mergedRows = []; // Tableau pour stocker les lignes fusionnées
    let currentRow = { ...rows[0] }; // Copie de la première ligne

    // Parcours de toutes les lignes du tableau
    for (let i = 1; i < rows.length; i++) {
      // Parcours des colonnes à fusionner
      currentRow.rowspan = [];
      for (let k = 0; k <= columnIndex.length - 1; k++) {
        let attribut = columnIndex[k];

        currentRow.rowspan[attribut] = 1; // Propriété rowspan initiale pour la première ligne

        // Vérifie si la valeur de la colonne pour la ligne actuelle est identique à la valeur de la colonne pour la ligne précédente
        if (
          rows[i][attribut] &&
          rows[i][attribut] === currentRow[attribut]
        ) {
          currentRow.rowspan[attribut] += 1; // Incrémente rowspan si la valeur est identique
          delete rows[i][attribut]; // Suppression de l'attribut pour la ligne suivante

          // Parcours des lignes suivantes
          for (let j = i + 1; j <= rows.length - 1; j++) {
            // Verifie si la cellule de la ligne suivante a la meme valeur
            if (
              rows[j][attribut] &&
              rows[j][attribut] === currentRow[attribut]
            ) {
              delete rows[j][attribut]; // Suppression de l'attribut pour la ligne suivante
              currentRow.rowspan[attribut] += 1; // Incrémente rowspan si la valeur est identique

              // Fin du test
            } else {
              break;
            }
          }
        }
      }

      mergedRows.push(currentRow); // Ajout de la ligne
      currentRow = { ...rows[i] }; // Copie de la nouvelle ligne pour commencer une nouvelle fusion
    }
    return mergedRows; // Retourne les lignes fusionnées avec la propriété rowspan pour la colonne spécifiée
  };

  // Copie en profondeur du tableau
  const copiedData = useMemo(
    () => JSON.parse(JSON.stringify(data)),
    [data]
  );
  // Colonnes a merge
  const columnsToMerge = useMemo(
    () => columns.map((column) => column.mergeOn).filter(Boolean),
    [columns]
  );

  const mergedData = mergeCells(copiedData, columnsToMerge);

  const tableInstance = useTable({ columns, data: mergedData });
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  if (!mergedData.length) return null;
  return (
    <div className="merged-table-overlay">
      <div className="merged-table-wrapper">
        <table {...getTableProps()} className="merged-table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="normal-table-header"
              >
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <Cell cell={cell} />
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MergedRowTable;
