import FournisseursProduitsLayout
    from "../../../../components/layout/_fournisseurs_produits/FournisseursProduitsLayout";

import Loader from "../../../../components/ui/loader/Loader";
import React, {useState} from "react";
import useAuth from "../../../../hooks/useAuth";
import './style.scss'
import {getMisesAjour} from "../../../../services/fournisseurs_produits_apis";
import ContratsMajList from "../../../../components/ui/lists/ContratsMajList";


const MisesAJour=()=>{
    const { magasin } = useAuth(); // magasins code
    const [loading, setLoading] = useState(true); // Indicate the call has not finished. Default to true
    const [contrats, setContrats] = useState([]);

    React.useEffect(() => {
        const fetchData = async () => {
            const res = await getMisesAjour(magasin.mag_code);
            setContrats(res);
            console.log(res);
            setLoading(false);
        };

        fetchData();
    }, [magasin.mag_code]);
    if (loading) return <Loader />;


    return (
        <FournisseursProduitsLayout>
            <div className="mises-a-jour">
                <div className="mises-a-jour-title">
                    <h3>Liste des mises à jour des dernières semaines</h3>
                </div>
                <div    className="mises-a-jour-conteneur">
                {Object.entries(contrats).map(([semaine, contratsMagasin]) => {
                        return (
                            <ContratsMajList
                                key={semaine}
                                semaine={semaine}
                                contrats={contratsMagasin}
                            />
                        );
                    })
                }
                </div>
            </div>
        </FournisseursProduitsLayout>
    );

}
export default MisesAJour;