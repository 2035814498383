import React from 'react';
import './style.scss';

const BDCprintCommandButton = ({ fournisseur }) => {
  const prefix = 'https://api.inedis.fr';
  return (
    <>
      <a
        className="pdf-command-btn"
        href={`${prefix}/command-pdf/${fournisseur.bi_code}`}
        target="_blank"
        rel="noreferrer"
      >
        bon&nbsp;de&nbsp;commande
      </a>
    </>
  );
};

export default BDCprintCommandButton;
