import CLLayout from '../../../components/layout/CLLayout';
import React, { useState } from 'react';
import { getPam } from '../../../services/communication_apis';
import useAuth from '../../../hooks/useAuth';
import Loader from '../../../components/ui/loader/Loader';
import FPColorCardLink from '../../../components/ui/cards/_fournisseurs_produits/FPoptionCard';
import './style.scss';
import { DOMAIN } from '../../../config/env';

const Pam = () => {
  const { magasin } = useAuth();
  const [pamList, setPamList] = useState();
  const [loading, setLoading] = useState(true); // Indicate the call has not finished. Default to true

  /**
   * Récupère les Pam pour le magasin connecté
   */
  const fetchPam = React.useCallback(async () => {
    try {
      const data = await getPam(magasin.mag_code);
      setPamList(data);
    } catch (err) {
      console.log(err);
    }
  }, [magasin.mag_code]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchPam();
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [fetchPam]);

  if (loading)
    return (
      // attente du chargement des données
      <CLLayout>
        <Loader />
      </CLLayout>
    );
  return (
    <CLLayout>
      <div className="pam-wrap">
        <h3 className="pam-title">Animations magasins</h3>
        <ul className="pam-intro-list">
          <li>Temps fort</li>
          <li>
            <ul className="pam-intro-sous-list">
              <li>Echéance bons de command</li>
              <li>Offres du mois</li>
            </ul>
          </li>
          <li>Animations commerciales</li>
          <li>
            <ul>
              <li>Echéance bons de command</li>
              <li>Offres du mois</li>
            </ul>
          </li>
          <li>Suggestions pour animer votre magasin</li>
          <li>
            <ul>
              <li>Opérations de fidélisation </li>
            </ul>
          </li>
        </ul>
        <div className="ae-select-opt__content">
          <div className="ae-select-opt__option-list">
            {pamList.map((pam, index) => {
              let colorClass =
                index % 2 === 0
                  ? 'ae-option--blue'
                  : 'ae-option--green';
              return (
                <FPColorCardLink
                  to={`${DOMAIN.URL}_fichier/_pam/${pam.ch_fichier}`}
                  externalLink
                  className={`section ${colorClass}`}
                  key={pam.ch_code}
                >
                  {pam.ch_titre}
                </FPColorCardLink>
              );
            })}
          </div>
        </div>
      </div>
    </CLLayout>
  );
};
export default Pam;
