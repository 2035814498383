import React from 'react';
import './style.scss';

const LoginInput = ({ label, title, onChange, error, ...rest }) => {
  return (
    <>
      <div className="basic-input">
        <label htmlFor={rest.name} title={title}>
          {label}
        </label>
        <input {...rest} onChange={onChange} />
        <span className="error">{error ? <>{error}</> : null}</span>
      </div>
    </>
  );
};

export default LoginInput;
