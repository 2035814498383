import { useState } from 'react';

const useNotification = () => {
  const [isShowingNotification, setIsShowingNotification] =
    useState(false);

  const toggleNotification = () => {
    setIsShowingNotification(!isShowingNotification);
  };

  return {
    isShowingNotification,
    toggleNotification,
  };
};

export default useNotification;
