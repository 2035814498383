import React from 'react';
import { useOutletContext } from 'react-router-dom';
import BDCcommandeFournisseurTable from '../../../../components/ui/tables/communication/BDCcommandesFournisseurTable';
import './style.scss';

const BDCselectionCommandeAll = () => {
  const { prospectusList } = useOutletContext();
  const { fournisseursCommandsList } = useOutletContext();

  return (
    <>
      <div className="recap_command__container">
        {prospectusList.length ? (
          prospectusList.map((prosp, i) => {
            let fournisseursProspectus = {};

            // Get fournisseurs commands on actual prospectus
            if (fournisseursCommandsList.length >= 1) {
              fournisseursProspectus =
                fournisseursCommandsList.filter((fournisseur) => {
                  return (
                    fournisseur.PSP_CProspectus ===
                    prosp.psp_cprospectus
                  );
                });
            }

            return (
              <React.Fragment key={i}>
                {fournisseursProspectus.length >= 1 && (
                  <BDCcommandeFournisseurTable
                    prospectus={prosp}
                    fournisseurs={fournisseursProspectus}
                  />
                )}
              </React.Fragment>
            );
          })
        ) : (
          <>Pas de commande à afficher. </>
        )}
      </div>
    </>
  );
};

export default BDCselectionCommandeAll;
