import FournisseursProduitsLayout from "../../../components/layout/_fournisseurs_produits/FournisseursProduitsLayout";
import useAuth from "../../../hooks/useAuth";
import React, {useState} from "react";
import {getFormations} from "../../../services/fournisseurs_produits_apis";
import Loader from "../../../components/ui/loader/Loader";
import ReturnBtn from "../../../components/ui/button/ReturnBtn";
import AEprestationCard from "../../../components/ui/cards/_fournisseurs_produits/AEprestataireCard";
import "./style.scss"

const Formations =()=>{
    const { magasin } = useAuth(); // Magasin c
    const [formationsList, setFormationsList] =
        useState({});// ode
    const [loading, setLoading] = useState(true); // Indicate the call has not finished. Default to true

    /**
     * Récupère les sections pour le magasin
     */
    const fetchFormations = React.useCallback(async () => {
        try {
            const data = await getFormations(
                magasin.mag_code
            );
            setFormationsList(data);

        } catch (err) {
            console.log(err);
        }
    }, [magasin.mag_code]);
    React.useEffect(() => {
        const fetchData = async () => {
            try {
                await fetchFormations();
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [fetchFormations]);

    if (loading)
        return (
            <FournisseursProduitsLayout>
                <Loader />
            </FournisseursProduitsLayout>
        );
    if (!!!formationsList.length)
        return <FournisseursProduitsLayout> </FournisseursProduitsLayout>;


    return <FournisseursProduitsLayout>
        <div className="ae-formation-wrap">
        <ReturnBtn />
        <h3 className="ae-formation-title">Formations</h3>
        <div className="ae-formation">
            {formationsList.map((formation) => (
                <div
                    className="ae-formation-card-wrapper"
                    key={`presta_${formation.pre_id}`}
                >
                    <AEprestationCard prestation={formation} />
                </div>
            ))}
        </div>
    </div></FournisseursProduitsLayout>
}
export default Formations;
