import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import './style.scss';
import { Box, Collapse, IconButton } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import useAuth from '../../../../../hooks/useAuth';
import { setCommandProduct } from '../../../../../services/communication_apis';
import { formatPrix } from '../../../../../utils/dates';
import BDCpriceTable from '../../../cards/communication/BDCproductCard/BDCpriceTable';

const Row = (props) => {
  const { product } = props;
  const { onChangeProduct } = props;
  const [open, setOpen] = useState(false); // Open or not the collapse button
  const { magasin } = useAuth(); // Code magasin
  const [totalPrice, setTotalPrice] = useState(0); // total price
  const prefix =
    'http://extranet.inedis.fr/_fichier/_cmu/_bi_photos/'; // Prefix URL for image product
  const defaultImg =
    'https://semantic-ui.com/images/wireframe/image.png'; // Default image product

  // Product command information to send
  const [productCommand, setProductCommand] = useState({
    pbd_ligne: 0,
    mag_code: 0,
    bil_qte: 0,
    psp_cprospectus: 0,
    bil_paht: 0,
    bil_commentaire: '',
  });

  React.useEffect(() => {
    let selectedPrice = 'pbd_ht_promo';
    // Pick select price option to display
    if (product.bil_paht > 0) {
      if (product.pbd_ht_1promo === product.bil_paht) {
        selectedPrice = 'pbd_ht_1promo';
      }
      if (product.pbd_ht_2promo === product.bil_paht) {
        selectedPrice = 'pbd_ht_2promo';
      }
    }
    // Init product command
    const productCommandToSet = {
      pbd_ligne: product.pbd_ligne,
      mag_code: magasin.mag_code,
      bil_qte: product.bil_qte ?? 0,
      psp_cprospectus: product.psp_cprospectus ?? 0,
      bil_paht: product[selectedPrice] ?? 0,
      bil_commentaire: product.bil_commentaire ?? '',
    };
    setProductCommand(productCommandToSet);
  }, [magasin.mag_code, product]);

  // Refresh the total price on quantity or price change
  React.useEffect(() => {
    if (productCommand.bil_paht) {
      setTotalPrice(
        productCommand.bil_paht * productCommand.bil_qte ?? 0
      );
    }
  }, [productCommand]);

  // Modification
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setProductCommand({
      ...productCommand,
      [name]: value,
    });
  };

  // Select price for a given product and update the command for this product
  const handleSelectPrice = async (event) => {
    try {
      const name = event.target.name;
      const value = event.target.value;
      await setCommandProduct({
        ...productCommand,
        [name]: value,
      });
      setProductCommand({
        ...productCommand,
        [name]: value,
      });
    } catch (error) {
      console.log(error);
    } finally {
      onChangeProduct();
    }
  };

  // Save data modification in DB on blur
  const handleBlur = async () => {
    try {
      await setCommandProduct(productCommand);
    } catch (err) {
      console.log(err);
    } finally {
      onChangeProduct();
    }
  };

  // Delete command for this product
  const handleDelete = async () => {
    try {
      await setCommandProduct({
        ...productCommand,
        bil_qte: 0,
      });
    } catch (err) {
      console.log(err);
    } finally {
      onChangeProduct();
    }
  };

  return (
    <React.Fragment>
      <tr className="commande-table__body">
        <td className="commande-table__body-row">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </td>
        <td className="commande-table__body-row">
          <div className="product-img">
            <img
              src={`${prefix}${product.pbdp_nom_photo}`}
              alt="product img"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = defaultImg;
              }}
            />
          </div>
        </td>
        <td className="commande-table__body-row">
          {product.pbd_produit}
        </td>
        <td className="commande-table__body-row">
          <label className="select">
            <select
              className="select__field"
              name="bil_paht"
              onChange={(e) => handleSelectPrice(e)}
              value={productCommand.bil_paht}
            >
              <option value={product.pbd_ht_promo}>
                {product.pbd_ht_promo} €
              </option>
              {product.pbd_ht_1promo > 0 && (
                <option value={product.pbd_ht_1promo}>
                  {product.pbd_ht_1promo} €{' '}
                </option>
              )}
              {product.pbd_ht_2promo > 0 && (
                <option value={product.pbd_ht_2promo}>
                  {product.pbd_ht_2promo} €{' '}
                </option>
              )}
            </select>
          </label>
        </td>
        <td className="commande-table__body-row">
          <label className="input">
            <input
              className="input__field"
              placeholder=" "
              min={0}
              name="bil_qte"
              value={productCommand.bil_qte}
              onChange={(event) => handleChange(event)}
              onBlur={() => handleBlur(product)}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </label>
        </td>
        <td className="commande-table__body-row price-row">
          {formatPrix(totalPrice)}
        </td>
        <td className="commande-table__body-row action">
          <DeleteOutlineIcon
            className="action-trash"
            onClick={(event) => handleDelete(event)}
          />
        </td>
      </tr>
      <tr className="commande-table__body">
        <td colSpan="6">
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 0 }}>
              <table className="commande-table--collapse">
                <thead>
                  <tr className="commande-table__head">
                    <th className="commande-table__head-row">
                      Référencement
                    </th>
                    <th className="commande-table__head-row"></th>

                    <th className="commande-table__head-row">
                      Commentaire
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="commande-table__body">
                    <td className="commande-table__body-row code-row">
                      {product.pbd_reference}
                    </td>
                    <td className="commande-table__body-row">
                      <BDCpriceTable product={product} />
                    </td>

                    <td
                      rowSpan="3"
                      className="commande-table__body-row"
                    >
                      <label className="text-area">
                        <textarea
                          className="text-area__field"
                          rows="3"
                          maxlength="255"
                          placeholder="Votre commentaire"
                          name="bil_commentaire"
                          value={productCommand.bil_commentaire}
                          onChange={(event) => handleChange(event)}
                          onBlur={() => handleBlur(product)}
                        />
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </Collapse>
        </td>
      </tr>
    </React.Fragment>
  );
};

const BDCcommandeTable = ({ products, onChangeProduct }) => {
  return (
    <>
      {products.length ? (
        <>
          <table className="commande-table">
            <thead>
              <tr className="commande-table__head">
                <th className="commande-table__head-row"></th>
                <th className="commande-table__head-row"></th>
                <th className="commande-table__head-row">
                  Désignation
                </th>
                <th className="commande-table__head-row">
                  PA&nbsp;HT&nbsp;promo&nbsp;(€)
                </th>
                <th className="commande-table__head-row">Quantité</th>
                <th className="commande-table__head-row">Total</th>
                <th className="commande-table__head-row">Action</th>
              </tr>
            </thead>
            <tbody>
              {products.length ? (
                products.map((product) => (
                  <Row
                    key={product.pbd_ligne}
                    product={product}
                    onChangeProduct={onChangeProduct}
                  />
                ))
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default BDCcommandeTable;
