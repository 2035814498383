import React, { useCallback, useState } from 'react';
import { Outlet } from 'react-router-dom';
import FournisseursProduitsLayout from '../../../components/layout/_fournisseurs_produits/FournisseursProduitsLayout';

import Loader from '../../../components/ui/loader/Loader';
import useAuth from '../../../hooks/useAuth';
import { getSectionsWithPrestataires } from '../../../services/fournisseurs_produits_apis';
import './style.scss';

const AE = () => {
  const { magasin } = useAuth(); // Magasin code
  const [sectionsList, setSectionsList] = useState({});
  const [loading, setLoading] = useState(true); // Indicate the call has not finished. Default to true

  /**
   * Récupère les sections pour le magasin
   */
  const fetchSections = useCallback(async () => {
    try {
      const data = await getSectionsWithPrestataires(magasin.mag_code);
      setSectionsList(data);
    } catch (err) {
      console.log(err);
    }
  }, [magasin.mag_code]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchSections();
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [fetchSections]);

  // Données partagées
  const data = {
    sectionsList,
  };

  if (loading)
    return (
      <FournisseursProduitsLayout>
        <Loader />
      </FournisseursProduitsLayout>
    );
  if (!!!sectionsList.length)
    return <FournisseursProduitsLayout> </FournisseursProduitsLayout>;
  return (
    <FournisseursProduitsLayout>
      <div className="agencement-exploitation">
        <Outlet context={data} />
      </div>
    </FournisseursProduitsLayout>
  );
};

export default AE;
