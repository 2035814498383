import React from 'react';
import CLdevisLayout from '../../../../components/layout/CLdevisLayout';
import CLsmsbddForm from '../../../../components/ui/forms/communication/CLsmsbddForm';

const CLsmsbdd = () => {
  return (
    <CLdevisLayout
      title="SMS en location de BDD avec ou sans landing page"
      alert="la demande doit être faite au minimum 14 jours avant le début de l'opération"
    >
      <CLsmsbddForm />
    </CLdevisLayout>
  );
};

export default CLsmsbdd;
