import React, { useState } from 'react';
import './style.scss';
import { Link } from 'react-router-dom';
import useAuth from '../../../../../hooks/useAuth';
import MenuToggleButton from '../../../../ui/button/MenuToggleButton';
import { links } from '../links';

const Hamburger = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const { onLogout } = useAuth();
  const { user } = useAuth();
  const { authed } = useAuth();
  const { magasin } = useAuth();

  const handleToggle = () => {
    setNavbarOpen((prev) => !prev);
  };

  return (
    <div
      className={`hamburger ${navbarOpen ? ' hamburger--open' : ''}`}
    >
      <MenuToggleButton toggle={handleToggle} />
      <div className={`ham-nav ${navbarOpen ? ' showMenu' : ''}`}>
        <ul className="ham-nav__menu">
          {links(magasin).map((link, index) => (
            <>
              <li className="ham-nav__link" key={link.label}>
                {link.items ? (
                  <div className="multiple-links">
                    <span>{link.label}</span>
                    <ul>
                      {link.items.map((item) => (
                        <>
                          <li>
                            <Link
                              onClick={() => handleToggle()}
                              to={item.to}
                            >
                              {item.label}
                            </Link>
                          </li>
                        </>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <>
                    <Link onClick={() => handleToggle()} to={link.to}>
                      {link.label}
                    </Link>
                  </>
                )}
              </li>
            </>
          ))}
        </ul>
        <div className="ham-nav__options">
          {(authed && (
            <>
              {user && (
                <div className="user-options">
                  <Link to="/profile">{magasin.mag_nom}</Link>{' '}
                </div>
              )}
              <button
                type="button"
                className="log-btn"
                onClick={onLogout}
              >
                Deconnexion
              </button>
            </>
          )) ||
            (!authed && <Link to="/login">Connexion</Link>)}
        </div>
      </div>
    </div>
  );
};

export default Hamburger;
