import React from 'react';
import './style.scss';
import SearchIcon from '../../icons/SearchIcon';

const SearchInput = ({ onChange, ...rest }) => {
  return (
    <div className="search-input-wrapper">
      <div className="search-icon">
        <SearchIcon />
      </div>
      <input
        {...rest}
        type="text"
        onChange={onChange}
        className="search-input"
      />
    </div>
  );
};

export default SearchInput;
