import { PATH } from '../../../config/constants';
import BasketIcon from '../../ui/icons/BasketIcon';
import EuroIcon from '../../ui/icons/EuroIcon';
import FileArrowDownload from '../../ui/icons/FileArrowDownload';
import PhoneIcon from '../../ui/icons/PhoneIcon';
import StoreFrontIcon from '../../ui/icons/StoreFrontIcon';

const links = (magasin) => [
  {
    href: PATH.BOUTIQUE_EN_LIGNE.replace(
      ':mag_code',
      magasin.mag_code
    ).replace(':mag_token', magasin.mag_token), //lien externe vers origine.extranet.fr,
    libelle: 'BOUTIQUE EN LIGNE',
    type: 'filled',
    icon: <BasketIcon height={50} width={50} />,
  },
  {
    to: PATH.MISES_A_JOUR,
    libelle: 'MISE A JOUR TARIFS',
    type: 'filled',
    icon: <EuroIcon height={50} width={50} />,
  },
  {
    to: PATH.PAM,
    libelle: 'PAM',
    sublibelle: 'Plan animation magasin',
    type: 'filled',
    icon: <StoreFrontIcon height={50} width={50} />,
  },
  {
    to: PATH.TELECHARGEMENT_COM,
    libelle: 'Documents à télécharger',
    type: 'filled',
    icon: <FileArrowDownload height={50} width={50} />,
  },
  {
    to: PATH.CONTACT_PAGE,
    libelle: 'Contacter Inédis',
    type: 'filled',
    icon: <PhoneIcon height={50} width={50} />,
  },
];

export const coloredLinks = (magasin) =>
  links(magasin).map((link, index) => {
    const colorClass =
      index % 2 === 0 ? 'filled-link--blue' : 'filled-link--green';
    return {
      ...link,
      class: `${link.class} ${colorClass}`,
    };
  });
