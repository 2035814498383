import React from 'react';
import ArticleTable from '../../../../../components/ui/tables/_fournisseurs_produits/ArticleTable';
import './style.scss';
import FournisseursProduitsLayout from '../../../../../components/layout/_fournisseurs_produits/FournisseursProduitsLayout';
import ReturnBtn from '../../../../../components/ui/button/ReturnBtn';

const Articles = () => {
  return (
    <FournisseursProduitsLayout>
      <div className="articles">
        <ReturnBtn />
        <h3 className="articles-title">Articles</h3>
        <ArticleTable />
      </div>
    </FournisseursProduitsLayout>
  );
};

export default Articles;
