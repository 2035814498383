import React, { useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import * as Yup from 'yup';
import { PATH } from '../../../config/constants';
import { Link, Navigate } from 'react-router-dom';
import PasswordInput from '../../../components/ui/input/PasswordInput';
import Input from '../../../components/ui/input/LoginInput';
import Button from '../../../components/ui/button/Button';

const Login = () => {
  const { onLogin, authed } = useAuth();
  const [error, setError] = useState(''); // Erreur sur submition des credentials
  const [errors, setErrors] = useState({}); // Erreur de validation des champs
  const [loading, setLoading] = useState(false); // Indicateur de chargement pour la connection
  const [data, setData] = useState({
    username: '',
    password: '',
  });

  // Validation des données du formulaire
  const validationSchema = Yup.object().shape({
    username: Yup.string().required('* Ce champs est obligatoire'),
    password: Yup.string().required('* Ce champs est obligatoire'),
  });

  // Modification des valeurs des champs
  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    // Validation des champs de saisie
    const validate = async () => {
      const errorBag = {};
      await validationSchema
        .validate(data, { abortEarly: false })
        .catch((err) => {
          err?.inner.forEach((res) => {
            errorBag[res.path] = res.message;
          });
        });
      setErrors(errorBag);
      if (Object.keys(errorBag).length) {
        throw new Error('Champs invalides');
      }
    };
    try {
      event.preventDefault();
      validate().then(() => {
        setError('');
        setLoading(true);
        onLogin({ ...data }).catch((err) => {
          setLoading(false);
          setError(err.response.data.error);
        });
      });
    } catch (err) {
      console.log(err);
    }
  };

  if (authed) return <Navigate to={{ pathname: PATH.HOME_PAGE }} />;
  return (
    <form className="form" onSubmit={(event) => handleSubmit(event)}>
      <h3> Connexion </h3>
      <div className="form-inputs">
        <Input
          type="text"
          name="username"
          label="Utilisateur"
          placeholder="Utilisateur"
          value={data.username}
          onChange={(event) =>
            handleChange(event.target.name, event.target.value)
          }
          error={errors.email}
          autoFocus
          autoComplete="username"
        />
        <PasswordInput
          type="password"
          name="password"
          label="Mot de passe"
          placeholder="Mot de passe"
          value={data.password}
          onChange={(event) =>
            handleChange(event.target.name, event.target.value)
          }
          error={errors.password}
          autoComplete="current-password"
        />
        <span className="form-error">{error && <>{error}</>}</span>
      </div>

      <div className="form-submit">
        <Button
          type="submit"
          loading={loading}
          libelle="Connexion"
          color="primary-btn"
        />
        <Link to={PATH.FORGOT_PASSWORD}>mot de passe oublié ?</Link>
      </div>
    </form>
  );
};

export default Login;
