import React from 'react';
import FormControlOverlay from '../FormControlOverlay';

const Textarea = ({
  label,
  onChange,
  children,
  title,
  error,
  ...rest
}) => {
  return (
    <FormControlOverlay label={label} error={error}>
      <textarea {...rest} onChange={onChange} />
    </FormControlOverlay>
  );
};

export default Textarea;
