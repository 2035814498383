import React from 'react';
import './style.scss';

const FormControlOverlay = ({ label, error, children }) => {
  return (
    <div className="form-control-overlay">
      <label className="label">{label}</label>
      <div
        className={`form-control ${error && 'form-control--error'}`}
      >
        {children}
      </div>
      <span className="error">{error ? <>{error}</> : null}</span>
    </div>
  );
};

export default FormControlOverlay;
