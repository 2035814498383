import React, { useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import ReturnBtn from '../../../../components/ui/button/ReturnBtn';
import BDCcommandeFournisseurTable from '../../../../components/ui/tables/communication/BDCcommandesFournisseurTable';
import './style.scss';

const BDCselectionCommandeProspectus = () => {
  const params = useParams();
  const prospectusId = params.prospectusId; // Prospectus ID
  const [prospectus, setProspectus] = useState({}); // Actual prospectus
  const { prospectusList } = useOutletContext(); // Prospectus list of the user
  const [fournisseurs, setFournisseurs] = useState({}); // Fournisseurs list with user commands of this specific prospectus
  const { fournisseursCommandsList } = useOutletContext(); // Fournisseurs list with user commands

  React.useEffect(() => {
    // // Get only fournisseurs with user commands for this prospectus
    if (fournisseursCommandsList.length >= 1) {
      const fournisseursFiltered = fournisseursCommandsList.filter(
        (fournisseur) => {
          return (
            fournisseur.psp_cprospectus === parseInt(prospectusId)
          );
        }
      );
      setFournisseurs(fournisseursFiltered);
    }
    // Find prospectus selected in the list of prospectus
    if (prospectusList.length >= 1) {
      const prospectusToFind = prospectusList.find(
        (p) => p.psp_cprospectus === parseInt(prospectusId)
      );
      setProspectus(prospectusToFind);
    }
  }, [fournisseursCommandsList, prospectusId, prospectusList]);

  return (
    <div className="selection-commande-prospectus">
      <ReturnBtn />
      <BDCcommandeFournisseurTable
        prospectus={prospectus}
        fournisseurs={fournisseurs}
      />
    </div>
  );
};

export default BDCselectionCommandeProspectus;
