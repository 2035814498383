import { DOMAIN } from '../config/env';
import api from './apiClient';

/**
 * Connecte un utilisateur, recupere le JWT et les informations sur l'utilisateur
 */
export async function userLogin(loginCredentials) {
  const url = DOMAIN.URL_API + 'auth/login';

  // Récupération du JWT
  let response = await api.post(url, {
    ...loginCredentials,
  });
  const jwt = response.data.access_token;
  localStorage.setItem('token', jwt);

  // Récupération de l'user
  return await userAuth();
}

/**
 * Recupere les informations sur l'utilisateur
 */
export async function userAuth() {
  const url = DOMAIN.URL_API + 'auth/me';
  const response = await api.get(url);

  return response.data;
}

/**
 * Deconnecte l'utilisateur
 */
export async function userLogout() {
  const url = DOMAIN.URL_API + 'auth/logout';
  await api.post(url);
}

/**
 * Envoie un mail de réinitialisation de mot de passe
 */
export async function forgotPassword(username) {
  const url = DOMAIN.URL_API + 'auth/forgot-password';

  const data = {
    username: username,
  };

  return api.post(url, { ...data });
}

/**
 * Envoie un mail de réinitialisation de mot de passe
 */
export async function resetPassword(data) {
  const url = DOMAIN.URL_API + 'auth/reset-password';

  return api.post(url, { ...data });
}

/**
 * Réinitialise le mot de passe
 */
export async function updatePassword(data) {
  const url = DOMAIN.URL_API + 'auth/user-password';

  return api.put(url, { ...data });
}
