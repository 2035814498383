import React, { useState } from 'react';
import './style.scss';
import { Outlet } from 'react-router-dom';
import CLLayout from '../../../components/layout/CLLayout';
import {
  getPotentiel,
  getZoneDistri,
} from '../../../services/communication_apis';
import useAuth from '../../../hooks/useAuth';

const CL = () => {
  const { magasin } = useAuth(); // Magasin code
  const [zoneDistri, setZoneDistri] = useState({}); // Zone distri of the magasin
  const [potentiel, setPotentiel] = useState({}); // Portentiel pour certains calculs de devis

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const dataZone = await getZoneDistri(magasin.mag_code);
        setZoneDistri(dataZone);
        const dataPotentiel = await getPotentiel(magasin.mag_code);
        setPotentiel(dataPotentiel[0]);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [magasin.mag_code]);

  return (
    <CLLayout>
      <Outlet
        context={{
          zoneDistri,
          potentiel,
        }}
      />
    </CLLayout>
  );
};

export default CL;
