import React, { useRef } from 'react';
import Modal from '@mui/material/Modal';
import { useNavigate, useOutletContext } from 'react-router-dom';
import './style.scss';
import { deleteCommand } from '../../../../services/communication_apis';

const ConfirmDeleteModal = ({
  onClose,
  codeCommand,
  path,
  hideParent,
}) => {
  const navigate = useNavigate();
  const { fournisseursCommandsList } = useOutletContext(); // Fournisseurs list with user commands
  const { setFournisseursCommandsList } = useOutletContext();

  const [open, setOpen] = React.useState(false);

  const handleDelete = (event) => {
    event.preventDefault();
    try {
      deleteCommand(codeCommand).then(() => {
        setFournisseursCommandsList(
          fournisseursCommandsList.filter((fournisseur) => {
            return fournisseur.bi_code !== codeCommand;
          })
        );
        onClose();
        navigate(path);
      });
    } catch (err) {
      console.log(err);
    }
  };
  const handleOpen = () => {
    setOpen(true);
    hideParent();
  };

  return (
    <React.Fragment>
      <button className="btn-delete" onClick={handleOpen}>
        supprimer
      </button>
      <Modal
        hideBackdrop
        open={open}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <div className="delete-command-confirm-modal">
          <div className="delete-command-confirm-modal__container">
            <h4 className="text-danger">Êtes-vous sûre ? </h4>
            <div className="btn-wrap">
              <button className="btn-delete" onClick={handleDelete}>
                supprimer
              </button>
              <button className="btn-primary" onClick={onClose}>
                annuler{' '}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

const BDCdeleteCommandeButton = ({ codeCommand, path }) => {
  const modal = useRef('');
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const hideModal = () => {
    // modal.current.style.display = "none";
    modal.current.classList.add('hidden');
  };

  return (
    <div>
      <button className="btn-delete" onClick={() => handleOpen()}>
        supprimer
      </button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <div ref={modal} className="delete-command-modal">
          <div className="delete-command_modal__container">
            <h4 className="text-danger">Supprimer la commande</h4>
            <p className="text-info delete-command_modal__text">
              Tous les enregistrements de la commandes seront
              supprimés.
            </p>
            <div className="btn-wrap">
              <ConfirmDeleteModal
                onClose={handleClose}
                codeCommand={codeCommand}
                path={path}
                hideParent={hideModal}
              />
              <button className="btn-primary" onClick={handleClose}>
                annuler
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BDCdeleteCommandeButton;
