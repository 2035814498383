import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import UniversProduitsLayout from '../../../components/layout/_fournisseurs_produits/UniversProduitsLayout';
import {
  fetchMetiers,
  setIdMetier,
} from '../../../features/metierSlice';
import useAuth from '../../../hooks/useAuth';
import Loader from '../../../components/ui/loader/Loader';
import { METIER, PATH } from '../../../config/constants';

const UniversProduits = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { magasin } = useAuth();
  const { metiers, loading, idMetier } = useSelector(
    (state) => state.metier
  );

  /**
   * Chargement des données
   */
  React.useEffect(() => {
    // Charger les données des métiers au chargement de la page
    if (loading) {
      dispatch(fetchMetiers(magasin.mag_code));
    }
  }, [dispatch, loading, magasin.mag_code]);

  /**
   * Assigne l'idMetier si il est dans les parametre et verifie qu'il existe bien dans la liste
   * Redirige sinon
   */
  React.useEffect(() => {
    const idMetierParam = parseInt(params.idMetier);
    if (idMetierParam) {
      // Si les données on étés chargées
      if (!loading) {
        const metierExiste = metiers.find(
          (metier) => metier.met_code === idMetierParam
        );

        if (metierExiste) {
          dispatch(setIdMetier(idMetierParam));
        } else {
          // Si l'idMetier n'existe pas, rediriger vers la page de sélection de métier
          navigate(PATH.UP_SELECTION_METIER);
        }
      }
      // Cas pours tous les metiers
    } else if (idMetierParam === METIER.METIER_ID_ALL) {
      dispatch(setIdMetier(idMetierParam));
    }
    // Redirection si aucun idMetier et si ce n'est pas la page de selection de metier
   /*
   22/08/2023 SUPPRESSIOn PAR LIO car empeche d'ouvrir certaines pages pour tous les Univers
   else if (!idMetier) {
      if (location.pathname !== PATH.UP_SELECTION_METIER) {
        navigate(PATH.UP_SELECTION_METIER);
      }
    }*/
  }, [
    dispatch,
    loading,
    location.pathname,
    magasin.mag_code,
    metiers,
    navigate,
    params,
    idMetier,
  ]);

  if (loading)
    return (
      <UniversProduitsLayout>
        <Loader />
      </UniversProduitsLayout>
    );
  if (!metiers.length) {
    return (
      <UniversProduitsLayout>
        <p>Pas de métiers disponibles.</p>
      </UniversProduitsLayout>
    );
  }
  return (
    <UniversProduitsLayout>
      <Outlet />
    </UniversProduitsLayout>
  );
};

export default UniversProduits;
