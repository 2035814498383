import React from 'react';
import FormControlOverlay from '../FormControlOverlay';

const Select = ({
  label,
  onChange,
  children,
  title,
  error = '',
  ...rest
}) => {
  return (
    <FormControlOverlay label={label} error={error}>
      <select {...rest} onChange={onChange}>
        {children}
      </select>
    </FormControlOverlay>
  );
};

export default Select;
