import useModal from '../../../../../hooks/useModal';
import SharpButton from '../../../button/SharpButton';
import ValidationMailModal from '../../ValidationMailModal';
import './style.scss';

const SalonInscriptionModal = ({
  isValidForm,
  onValidation,
  email,
  children,
  ...props
}) => {
  const { isShowing, toggle, openModal } = useModal();

  const handleValidation = async () => {
    await onValidation();
    toggle();
  };

  return (
    <div>
      <SharpButton
        type="submit"
        libelle="Confirmer mon inscription"
        onClick={openModal}
      />

      <ValidationMailModal
        isShowing={isValidForm && isShowing}
        hide={toggle}
        onClick={() => handleValidation()}
        title="Inscription au salon"
      >
        <div className="salon-inscription-modal">
          <p>
            Un mail récapitulatif va vous être envoyé à l'adresse
            <span>{email}</span>
          </p>
        </div>
      </ValidationMailModal>
    </div>
  );
};

export default SalonInscriptionModal;
