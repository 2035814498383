export const DOMAIN = {
  /// CONFIG DEVELOPPEMENT
  // URL: 'https://origine.inedis.fr/',
  // URL_API: 'http://192.168.111.111/api/',
  // URL_WEB: 'http://192.168.111.111/',
  // URL_APP: 'http://192.168.111.111:3000/',
  URL: 'https://origine.inedis.fr/',
  URL_API: 'https://api.inedis.fr/api/',
  URL_WEB: 'https://api.inedis.fr/',
  URL_APP: 'https://expreprod.inedis.fr/',
};

export const STATUS = {
  MAINTENANCE: false,
};
