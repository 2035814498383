import { DOMAIN } from './env';

/** ******************************** ROUTES ********************************  */
export const PATH = {
  HOME_PAGE: '/',
  PROFILE: '/profile',
  NEWSLETTER: '/newsletter',

  // Authentification
  LOGIN_PAGE: '/login',
  RESET_PASSWORD: '/reset-password/:token/:username',
  FORGOT_PASSWORD: '/forgot-password',
  CHANGE_PASSWORD: '/change-password/:username',
  // Contact
  CONTACT_PAGE: '/contact',
  SELECT_MAGASIN: '/select-magasin',

  // Bons de commandes
  BDC_PROSPECTUS_PAGE: '/bdc/prospectus',
  BDC_PRODUITS_PAGE: '/bdc/prospectus/:prospectusId',
  BDC_COMMANDES_ALL_PAGE: '/bdc/commanderecap',
  BDC_COMMANDES_PROSPECTUS_PAGE: '/bdc/commanderecap/:prospectusId',
  BDC_COMMANDE_PAGE:
    '/bdc/commanderecapfournisseur/:prospectusId/:codeFournisseur',

  // Autre rubriques communication
  PLAN_DE_COM:
    DOMAIN.URL +
    'ex_base.php?mag=:mag_code&key=:mag_token&do=communication&sdo=cmu&ssdo=planmedia',
  BOUTIQUE_EN_LIGNE:
    DOMAIN.URL +
    'ex_base.php?mag=:mag_code&key=:mag_token&do=communication&sdo=boutique',
  PAM: 'com/pam',
  TELECHARGEMENT_COM: 'com/dl',
  // Communications locales
  CL_OPTIONS_PAGE: '/cl/select-option',

  CL_OPTION_1_PAGE: '/cl/option1',
  CL_OPTION_2_PAGE: '/cl/option2',
  CL_OPTION_3_PAGE: '/cl/option3',
  CL_OPTION_4_PAGE: '/cl/option4',
  CL_OPTION_56_PAGE: '/cl/option56/:option',

  /** FOURNISSEURS ET PRODUITS */
  //Produits
  AIDE_ACHAT_VTE: '/fp/aide-achat-vte',
  CONTRAT_DETAILS: '/fp/up/contrat/:con_code',
  UP_SELECTION_METIER: '/fp/up/selection-metier',
  UP_SELECTION_INFO: '/fp/up/selection-metier/:idMetier',
  PLANO_BASE_URL: '/fp/up/planos',
  PLANOS: '/fp/up/planos/:idMetier',
  PLANOS_DETAILS: '/fp/up/planos/:idMetier/detail/:pga_code',
  PRECO_FOURNISSEUR: '/fp/up/precofour/:idMetier',
  ARTICLES: '/fp/up/articles/:con_code',
  ARTICLES_SUP: '/fp/up/articles-sup/:con_code',
  MISES_A_JOUR: '/fp/up/maj',
  // Agencement-exploitation
  AE_SECTIONS: '/fp/agencement-exploitation',
  AE_SECTION: '/fp/agencement-exploitation/:sec_id',
  LEGISLATION: '/fp/legislation',
  FORMATION: '/fp/formations',
  SOLUTION: '/fp/solutions',
  DOCS_EVENT: '/fp/documents-evenementiel',
  MDG_PAGE: '/fp/mdg',
  MDG_SRUB_PAGE: '/fp/mdg/:dsr_code',
  //Salon
  SALON_INSCRIPTION: '/salon/inscription',
};

export const REDIRECT_LOGIN = PATH.HOME_PAGE;

export const RESSOURCE = {
  DOWNLOAD: '_fichier/_dl/',
  CHRONO: '_fichier/_chrono/',
  PHOTO_FOURNISSEUR: '_fichier/_referencia/_photosfour/',
  PHOTO_PLANOS: '_fichier/_referencia/_plano/',
  CONTRATS_CARTES: '_fichier/_referencia/_cartes/',
  CONTRATS_CGV: '_fichier/_referencia/_cgv/',
  CONTRATS_FICHES: '_fichier/_referencia/_fbible/',
  BOOK: '_fichier/_book/_file/',
  BOOK_CATALOGUE: '_fichier/_book/_catalogue/',
  MDG_ICON: '_img/logo_inedits.jpg',
  PLAN_DE_COM_COUV: '_fichier/_cmu/_op/couv/',
};
// ROLES users
export const ROLES = {
  ADMIN: 1,
  MAGASIN: 2,
  INEDIS: 3,
  MULTIMAG: 4,
};

// liens d'exxport vers le BACK
export const EXPORTS = {
  CONTRATS: 'export-contrats/:magasin/metier/:idMetier',
  ARTICLES: 'export-articles/:magasin/contrat/:con_code',
  ARTICLES_LYNX:
    'export-articles-lynx/:magasin/contrat/:con_code/:tarif',
};

export const ERROR_MESSAGE = {
  REQUIRED: '* Ce champs est obligatoire',
  FORMAT: '* Format invalide (ex : 1000.52)',
  LONG_STRING: '* Le champ ne doit pas dépasser 255 caractères',
  OVERTEXT: '* Trop de caractères',
  MIN_DATE: '* La date ne peut pas être antérieur au ',
  MIN_WEEK: '* La semaine ne peut pas être antérieure à la semaine ',
};

export const SOUS_RUBRIQUE = {
  LEGISLATION: 12,
  DOCUMENTS_EVENEMENTIEL: 26,
  TELECHARGEMENT_COM: 19,
  AIDE_ACHAT_VTE: 11,
};

export const METIER = {
  METIER_ID_ALL: 0,
  METIER_LIBELLE_ALL: 'Tous les univers',
  METIER_ID_CHRONO: [2],
};
