import React from 'react';
import './style.scss';
import { Link } from 'react-router-dom';

const OutlinedLink = ({ libelle, className, to, icon, rest }) => {
  if (!libelle) return null;
  return (
    <Link className={className + ' outlined-link'} to={to} {...rest}>
      {icon}
      <span className="libelle">{libelle}</span>
    </Link>
  );
};

export default OutlinedLink;
