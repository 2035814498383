// Fonction de calcul du montant approximatif pour le devis de mailing
export const calculMailingCourrier = (tarification) => {
  let total = tarification.frais_fixes; // Frais fixes
  const quantite = tarification.quantite; // Nombre d'impression
  // Traitement postal
  total += quantite * tarification.traitement_postal;
  // Impression + Affranchissement
  if (quantite < tarification.seuil_commande) {
    total += quantite * tarification.cout_impression_1;
    total += quantite * tarification.affranchissement_1;
  } else {
    total += quantite * tarification.cout_impression_2;
    total += quantite * tarification.affranchissement_2;
  }
  return total;
};

// Fonction de calcul du montant approximatif pour le devis de sms ou de mail fid
export const calculSMSEmail = (tarification) => {
  let total = tarification.frais_fixes; // Frais fixes
  const minimum = tarification.minimum_commande; // Minimum de sms
  const quantite = tarification.quantite; // Nombre d'envoie
  let coeff = quantite > minimum ? quantite : minimum;
  // Impression
  total += coeff * tarification.cout_envoie;

  return total;
};

// Fonction de calcul du montant approximatif pour le devis de mailing
export const calculSMSbdd = (tarification) => {
  let total = tarification.frais_fixes; // Frais fixes
  const minimum = tarification.minimum_commande; // Minimum de sms
  const quantite = tarification.quantite; // Nombre d'envoie
  let coeff = quantite > minimum ? quantite : minimum;
  total += coeff * tarification.cout_envoie;

  return total;
};
